import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/service.css'
import dstv from'../images/dstv.png'
import gotv from'../images/gotv.png'
import startime from'../images/images.jpg'
import avatar from'../images/avatar.png'
import logo from'../logos/logo2.png'
import Activity from './includes/activity';

function Cable(){
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const navigate = useNavigate();
  
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
  
  const [planOpen, setPlanOpen] = useState(false);
  const togglePlan = () => {
    setPlanOpen(!planOpen);
  }
  
  
  const [network, setNetwork] = useState('');
  const [networkName, setNetworkName] = useState('');
  const [plan, setPlan] = useState('');
  const [planName, setPlanName] = useState('');
  
  const [cable_no, setCableNo] = useState('');
  const [cable_no_name, setCableNoName] = useState('');
  //pins
  const [pinOne, setPinOne] = useState('');
  const [pinTwo, setPinTwo] = useState('');
  const [pinThree, setPinThree] = useState('');
  const [pinFour, setPinFour] = useState('');
  
  const [touchStatus, setTouchStatus] = useState(true);
  //-----------
  const [amount, setAmount] = useState('');
  const [disabledAmount, setDisabledAmount] = useState('');
  
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);
  
  const [ids, setIds] = useState([]);
  const [currentIds, setCurrentIds] = useState([]);
  
  const [getPlan, setGetPlan] = useState(false);
  
  const handelChange = (e, nextInputRef) => {
    if(e.target.value.length === e.target.maxLength){
      nextInputRef.current.focus();
    }
  }
  
  const handelPrevious = (e, previousInputRef) => {
    if(e.key === "Backspace" && e.target.value.length === 0){
      previousInputRef.current.focus();
    }
  }
  
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const handelCancel = () => {
    window.history.back();
  }
  
  const submitForm = () => {
    if(network === ""){
      showMessage("error", "Please select network");
    }else if(plan === ""){
      showMessage("error", "Please select data plan");
    }else if(cable_no.length < 5){
      showMessage("error", "Please enter a valid cable number required 11 digits");
    }else if(cable_no_name === ""){
      showMessage("error", "Please verify cable name before proceeding.");
    }else{
      handleDisplay();
    }
  }
  
  const g_img = document.querySelectorAll(".img");
  const toggClass = () => {
    if(touchStatus === true){
      g_img.forEach(allImg => {
        allImg.classList.remove("active");
      });
    }
  }
  
  const toggleDetails = () => {
    document.querySelector(".details").classList.toggle("active");
  }
  
  const handleDisplay = () => {
    document.querySelector(".fixed-final").classList.toggle("active");
  }
  
  
  useEffect(()=> {
      fetch(`https://${domain_name}/get-data-plans.php`)
      .then(response => response.json())
      .then(data => {
        setGetPlan(true);
        if(data.status !== true){
          showMessage("error", data.msg);
        }else{
          setPlans(data.plans);
          setIds(data.ids);
        }
      });
  }, []);
  
  
  useEffect(() => {
    const filteredPlans = plans.filter(p => p.service === networkName && p.status === "On");
    const filteredIds = ids.filter(p => p.service === "CABLE_TV" && p.status === "On");
    
    setCurrentPlan(filteredPlans);
    setCurrentIds(filteredIds);
  }, [networkName, plans, ids]);
  
  const get_btn_one = document.getElementById('verBtn');
  
  const verifyNo = ()=> {
      showP(get_btn_one, "<div class='roll'></div>", true, '0.5');
      fetch(`https://${domain_name}/verify-cable.php`, {
        method: "POST",
        body: JSON.stringify({
          cable_type: network,
          iuc_number: cable_no
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        if(data.status === true){
          setCableNoName(data.name);
          showMessage("success", "Cable name verification successful");
        }else{
          setCableNoName('');
          showMessage("error", JSON.stringify(data));
        }
        showP(get_btn_one, "verify", false, '1');
      })
      .catch(err => {
        showP(get_btn_one, "verify", false, '1');
        showMessage("error", "Server/Network error " + err);
      });
  }
  
  const get_btn = document.getElementById('smtBtn');
  
  const [rawHtml, setRawHtml] = useState(false);
  
  const payCable = () => {
    const pay_load = {
      user_id: user.unique_id,
      cable_type: network,
      cable_type_name: networkName,
      plan: plan,
      plan_name: planName,
      cable_no: cable_no,
      cable_no_name: cable_no_name,
      amount: amount,
      pin_one: pinOne,
      pin_two: pinTwo,
      pin_three: pinThree,
      pin_four: pinFour,
    };
    
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`${protocol}//${domain}/cable.php`, {
        method: "POST",
        body: JSON.stringify(pay_load),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "SUBMIT", false, '1');
        setTouchStatus(true);
        if(data.includes("<table>")){
          setRawHtml(true);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "SUBMIT", false, '1');
        setTouchStatus(true);
        showMessage("error", "Server/Network error");
      });
  }
  
  return (
    <>
      <Activity />
      <div className="top-fixed s-head">
        <i className="bi bi-chevron-left no" onClick={handelCancel}></i>
        <span>Cable TV</span>
        <img src={avatar} alt="Avatar image" onClick={toggleDetails} />
        
        <div className="details">
          <Link to="/dashboard"><button>
            <i className="bi bi-house no"></i>
            <span>Dashboard</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/profile"><button>
            <i className="bi bi-person no"></i>
            <span>Profile</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/settings"><button>
            <i className="bi bi-gear no"></i>
            <span>Settings</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/logout"><button className="red">
            <i className="bi bi-lock no"></i>
            <span>Logout</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
        </div>
      </div>
      
      <div className="s-service">
        <div className="form-design no-bg reduce-padding grid">
          <div className="form-topic bottom-space">
            <i className="bi bi-phone"></i>
            Buy Cable Tv
          </div>
          
          <div className="select-btns">
            <span className="span">Select Cable Type</span>
            
            <div className="imgs">
              {currentIds.map((p, index) => (
              <div className="img">
                <img src={
                p.provider === "GOtv" 
                ? gotv 
                : p.provider === "Startime"
                ? startime
                : p.provider === "DStv"
                ? dstv : ""} alt="service images" onClick={(e)=> {
                const g_img = document.querySelectorAll(".img");
                setNetwork(p.provider_id); 
                setNetworkName(p.provider);
                setPlanName('');
                setPlan('');
                toggClass();
                g_img[index].className = "img active"; }} />
                <span>{p.provider}</span>
              </div>
              ))}
          </div>
        </div>
         
          
          
          <div className="inputs none">
            <span>Select Plan</span>
            <select 
              id="plan"
              value={plan}
              onChange={(e) => {
                setPlan(e.target.value);
              }}
            >
              <option value="">-- Select Plan --</option>
              {currentPlan.map(p => (
                <option value={p.service_id}>{p.service} {p.name}/{p.validity} &#x20a6;{(p.amount).toLocaleString()}</option>
              ))}
            </select>
          </div>
          
          
          
          
          <div className="inputs" onClick={togglePlan}>
            <span>Select Plan</span>
            <i class="bi bi-grid"></i>
            <b>{planName || "-- Select Plan --"}</b>
            <i class={planOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            {planOpen && (
             <ul>
               {currentPlan.length > 0 ? (
                  currentPlan.map(p => (
               <li className={p.service_id === plan ? "active" : ""} onClick={() => {
                  setPlan(p.service_id);
                  const plan = document.getElementById("plan");
                  plan.value = p.service_id;
                  setPlanName(plan.options[plan.selectedIndex].text);
                  const amountMatch = plan.options[plan.selectedIndex].text.match(/₦(\d+)/);
                  if(amountMatch){
                    setAmount(amountMatch[1]);
                  }else{
                    setAmount('');
                  }
               }}>
               {p.service} {p.name}/{p.validity} &#x20a6;{(p.amount).toLocaleString()}</li>
                  ))
                 ) : (
                   <li>No data available</li>
               )}
             </ul>
            )}
            {getPlan == false ? (
             <div className="loader"><div></div></div>
            ) : ""}
          </div>
          
          
          
          <div className="inputs">
            <span>Enter Cable Number</span>
            <i class="bi bi-telephone"></i>
            <input 
              type="tel" 
              id="phone"
              placeholder="12837393899"
              maxLength="11"
              value={cable_no}
              onChange={(e) => setCableNo(e.target.value)}
            />
            <button class="verify" id="verBtn" onClick={verifyNo}>verify</button>
          </div>
          
          
          
          <div className="inputs disabled">
            <span>Cable Name</span>
            <i class="bi bi-person"></i>
            <input 
              type="text" 
              id="cable_name"
              placeholder="Jhon Doe"
              value={cable_no_name}
              disabled="true"
            />
          </div>
          
          
          
            <input 
              type="tel" 
              id="amount" 
              placeholder="1000" 
              value={amount}
              hidden="true"
              onChange={(e) => setAmount(e.target.value)}
             />
           
          
          <div className="pay-method">
            <b>How would you like to pay?</b>
            
            <div className="p-select">
              <input type="checkbox" name="pay-wallet" id="pay-wallet" checked />
              <label for="pay-wallet">Pay with wallet balance({user.format_balance})</label>
            </div>
            <div className="p-select">
              <input type="checkbox" name="pay-other" id="pay-other" disabled />
              <label for="pay-other">Pay with wallet orher methods</label>
            </div>
          </div>
          
          <button className="form-button" onClick={submitForm}>PROCEED</button>
          
        
        
        
          
          <div className="fixed-final">
            <div className="f-f-box">
              <div className="f-f-tp seperate bottom-space">
                <span>Confirm Transaction PIN</span>
                <i className="bi bi-x no" onClick={handleDisplay}></i>
              </div>
              <div className="f-f-topic">Please enter your transaction PIN to confirm the purchase of {planName} Cable Tv for {cable_no}({cable_no_name})</div>
            
            <div className="f-inputs">
              <input 
                type="tel" 
                id="pin-1"
                maxLength="1"
                ref={input1Ref}
                onKeyUp={(e) => handelChange(e, input2Ref)}
                onChange={(e) => setPinOne(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-2"
                maxLength="1"
                ref={input2Ref}
                onKeyUp={(e) => handelChange(e, input3Ref)}
                onKeyDown={(e) => handelPrevious(e, input1Ref)}
                onChange={(e) => setPinTwo(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-3"
                maxLength="1"
                ref={input3Ref}
                onKeyUp={(e) => handelChange(e, input4Ref)}
                onKeyDown={(e) => handelPrevious(e, input2Ref)}
                onChange={(e) => setPinThree(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-4"
                maxLength="1"
                ref={input4Ref}
                onKeyDown={(e) => handelPrevious(e, input3Ref)}
                onChange={(e) => setPinFour(e.target.value)}
              />
            </div>
            <div className="f-forgot">Forgot Payment PIN, <Link to="/settings"> Reset?</Link></div>
            
            <button className="form-button reduce-height" id="smtBtn" onClick={payCable}>SUBMIT</button>
            </div>
          </div>
          
          
        </div>
      </div>
      {getPlan === false ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
      
      {rawHtml ? (
      <div className="fixed-success">
         <div className="f-success-i">
           <i className="bi bi-check-circle-fill"></i>
         </div>
         
         <div className="f-success-topic">Cable Top-Up Successful</div>
         <div className="f-success-small-topic">Congratulations! your cable tv recharge of {planName} for {cable_no_name}({cable_no}) at amount of &#x20a6;{amount} was successful.</div>
         <div className="f-success-seperate">
           <Link to="/reciept"><button className="f-success-seperate-btn">
             <i className="bi bi-share-fill"></i>
             Reciept
           </button></Link>
           <Link to="/dashboard"><button className="f-success-seperate-btn">
             <i className="bi bi-house-fill"></i>
             Dashboard 
           </button></Link>
         </div>
         <div className="green-msg">Congratulations 🎉, you have just earned 2%({amount}) cashback of cable recharge.</div>
         
         <div className="f-success-grow"></div>
         
         <Link to="/dashboard"><button className="form-button blue-bg">DISMISS</button></Link>
      </div>
      ) : ""}
    </>
  );
}

export default Cable;