import React, { useState, useEffect } from 'react'

function Lisense(){
  useEffect(()=> {
    fetch("http://localhost:7070/app/api/verify.php", {
      method: "POST",
      body: JSON.stringify({
        domain: 'localhost:30090',
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.text())
    .then(data => {
      alert(data);
    })
    .catch(err => alert("Server/Network error"));
  }, []);
}

export default Lisense;