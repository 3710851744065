import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/transactions.css'
import logo from'../logos/logo2.png'
import Activity from './includes/activity';

function Transactions(){
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [search, setSearch] = useState('');
  
  const [trans, setTrans] = useState([]);
  const [reload, setReload] = useState(false);
  
const reloadT = ()=> {
    setReload(true);
    fetch(`https://${domain_name}/transactions.php`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.unique_id,
        limit: 0,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(data => {
      setReload(false);
      if(data.status == true){
        setTrans(data.transactions);
      }else{
        setReload(false);
        showMessage("error", data.msg);
      }
    })
    .catch(err => {
      setReload(false);
      showMessage("error", "Unable to fetch transactions");
    });
}

useEffect(()=> {
  reloadT();
}, []);

const [filteredTrans, setFilteredTrans] = useState([]);

useEffect(()=> {
  setFilteredTrans(trans);
  if(search !== ""){
    const seTr = trans.filter((transaction) =>
    transaction.type.toLowerCase().includes(search.toLowerCase()));
    setFilteredTrans(seTr);
  }else{
    setFilteredTrans(trans);
  }
}, [search, trans]);

function goBack(){
  window.history.back();
}

return(
  <>
    <Activity />
    <div className="head">
      <i className="bi bi-chevron-left no" onClick={goBack}></i>
      <span>Transaction History</span>
    </div>
    <div className="search-top">
      <div className="inputs-t">
        <i className="fas fa-search"></i>
        <input
        type="search"
        id="search" 
        placeholder="Search Transaction"
        value={search}
        onInput={(e)=> setSearch(e.target.value)}
        />
      </div>
    </div>
    <div className="all-t">
        {filteredTrans.length > 0 ? (
        filteredTrans.map(newT => {
            let iconClass = "";
            if(newT.type.includes("Airtime")){
              iconClass = "bi bi-telephone-fill";
            }else if(newT.type.includes("Data")){
              iconClass = "bi bi-phone-fill";
            }else if(newT.type.includes("Account Credited")){
              iconClass = "bi bi-arrow-down-circle-fill";
            }else if(newT.type.includes("Transfer")){
              iconClass = "bi bi-bank";
            }else if(newT.type.includes("Cable Tv")){
              iconClass = "bi bi-tv-fill";
            }else if(newT.type.includes("Electricity")){
              iconClass = "ri ri-lightbulb-fill";
            }else if(newT.type.includes("Betting")){
              iconClass = "ri ri-football-fill";
            }else{
              iconClass = "bi bi-arrow-up-circle-fill";
            }
        
            return (
             <Link to={`/reciept?ref=${newT.reference}`}><div key={newT.id} className="transaction-box-t">
               <i className={iconClass}></i>
               <div className="t-left">
                 <span>{newT.type}</span>
                 <b>{newT.date}</b>
               </div>
               <div className="t-right">
                 <b className={newT.status}>{newT.status}</b>
                 <span>NGN {parseInt(newT.amount).toLocaleString()}</span>
               </div>
             </div></Link>
            );
          })
        ) : (
         <div className="empty-box">
           <i className="bi bi-info-circle-fill"></i>
           <span>No transaction found for this account, make a transaction now.</span>
           <button class="reload-button" onClick={reloadT}><i class="bi bi-arrow-repeat no"></i> reload</button>
         </div>
        )}
    </div>
    
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
  </>
  );
}

export default Transactions;