import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from '../showMessage'
import '../../css/change.css'

function ChangePassword(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cNewPassword, setCnewPassword] = useState('');
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function toggleEye(inputName, eyeName){
    const getInput = document.getElementById(inputName);
    const getEye = document.getElementById(eyeName);
    if(getInput.type === "password"){
      getInput.type = "text";
      getEye.className = "bi bi-eye-slash";
    }else{
      getInput.type = "password";
      getEye.className = "bi bi-eye";
    }
  }
  
  function goBack(){
    window.history.back();
  }
  
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const get_btn = document.getElementById("uBtn");
  
  
  const updatePassword = (e) => {
    e.preventDefault();
    if(oldPassword === ""){
      showMessage("error", "Please enter old password");
    }else if(newPassword === ""){
      showMessage("error", "Please enter new password");
    }else if(newPassword.length < 8){
      showMessage("error", "Please enter a valid password minimum 8 digits");
    }else if(cNewPassword !== newPassword){
      showMessage("error", "Confirm password does not match new password");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      const link = `${protocol}//${domain_name}/update-password.php`;
      fetch(link, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          old_password: oldPassword,
          new_password: newPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "UPDATE", false, '1');
        if(data.includes("success")){
          showMessage("success", data);
          goBack();
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "UPDATE", false, '1');
        showMessage("error", "Server/Network error");
      });
    }
  }
  
  return(
    <div className="fixed-form active">
      <form className="f-form" onSubmit={updatePassword}>
        <div className="sep seperate">
          <b>Change Password</b>
          <i className="bi bi-x no" onClick={goBack}></i>
        </div>
        
        <div className="inputs">
          <span>Old Password</span>
          <input 
            type="password"
            id="old-password" 
            placeholder="********"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <i id="eye-1" className="bi bi-eye" onClick={() => toggleEye("old-password", "eye-1")}></i>
        </div>
        
        
        <div className="inputs">
          <span>New Password</span>
          <input 
            type="password"
            id="new-password" 
            placeholder="********"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <i id="eye-2" className="bi bi-eye" onClick={() => toggleEye("new-password", "eye-2")}></i>
        </div>
        
        
        <div className="inputs">
          <span>Confirm New Password</span>
          <input 
            type="password"
            id="confirm-new-password" 
            placeholder="********"
            value={cNewPassword}
            onChange={(e) => setCnewPassword(e.target.value)}
          />
          <i id="eye-3" className="bi bi-eye" onClick={() => toggleEye("confirm-new-password", "eye-3")}></i>
        </div>
        
        
        <button type="submit" id="uBtn" className="form-button reduce-height">UPDATE</button>
      </form>
    </div>
  );
}

export default ChangePassword;