import React, { useState, useEffect } from "react"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../css/msg.css"

function Msg({ message, type }){
  const [visible, setVisible] = useState(true);
  
  const handleCancel = () => {
    setVisible(false);
  }
  
  useEffect(() => {
    if(!visible){
      const box = document.getElementById("msg-bx");
      box.style.opacity = "0";
      setTimeout(()=> {
        return null;
      }, 1000);
    }
  }, [visible]);
  
  return (
    <div className="msg-bx" id="msg-bx">
      <div className={`msg-bx-inn ${type}`}>
       {message}
       <i className="bi bi-x" onClick={handleCancel}></i>
      </div>
    </div>
  );
}

export default Msg;