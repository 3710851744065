import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from '../showMessage'
import '../../css/change.css'

function ChangePin(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [cNewPin, setCnewPin] = useState('');
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function toggleEye(inputName, eyeName){
    const getInput = document.getElementById(inputName);
    const getEye = document.getElementById(eyeName);
    if(getInput.type === "password"){
      getInput.type = "text";
      getEye.className = "bi bi-eye-slash";
    }else{
      getInput.type = "password";
      getEye.className = "bi bi-eye";
    }
  }
  
  function goBack(){
    window.history.back();
  }
  
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const get_btn = document.getElementById("uBtn");
  
  
  const updatePassword = (e) => {
    e.preventDefault();
    if(oldPin === ""){
      showMessage("error", "Please enter old PIN");
    }else if(newPin === ""){
      showMessage("error", "Please enter new PIN");
    }else if(newPin.length < 4){
      showMessage("error", "Please enter a valid PIN minimum 4 digits");
    }else if(cNewPin !== newPin){
      showMessage("error", "Confirm PIN does not match new PIN");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      const link = `${protocol}//${domain_name}/update-pin.php`;
      fetch(link, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          old_pin: oldPin,
          new_pin: newPin,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "UPDATE", false, '1');
        if(data.includes("success")){
          showMessage("success", data);
          goBack();
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "UPDATE", false, '1');
        showMessage("error", "Server/Network error");
      });
    }
  }
  
  return(
    <div className="fixed-form active">
      <form className="f-form" onSubmit={updatePassword}>
        <div className="sep seperate">
          <b>Change PIN</b>
          <i className="bi bi-x no" onClick={goBack}></i>
        </div>
        
        <div className="inputs">
          <span>Old PIN</span>
          <input 
            type="number"
            id="old-pin" 
            placeholder="********"
            value={oldPin}
            onChange={(e) => setOldPin(e.target.value)}
          />
        </div>
        
        
        <div className="inputs">
          <span>New PIN</span>
          <input 
            type="number"
            id="new-pin" 
            placeholder="********"
            value={newPin}
            onChange={(e) => setNewPin(e.target.value)}
          />
        </div>
        
        
        <div className="inputs">
          <span>Confirm New PIN</span>
          <input 
            type="number"
            id="confirm-new-pin" 
            placeholder="********"
            value={cNewPin}
            onChange={(e) => setCnewPin(e.target.value)}
          />
        </div>
        
        
        <button type="submit" id="uBtn" className="form-button reduce-height">UPDATE</button>
      </form>
    </div>
  );
}

export default ChangePin;