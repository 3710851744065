import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import logo from'../logos/logo2.png'
import '../css/service.css'
import '../css/change.css'
import Activity from './includes/activity';

function UserTransfer(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const [amount, setAmount] = useState("");
  const [accNo, setAccNo] = useState("");
  const [accName, setAccName] = useState("");
  const [reload, setReload] = useState(false);
  
  const [reciever, setReciever] = useState("");
  
  const [pinOne, setPinOne] = useState('');
  const [pinTwo, setPinTwo] = useState('');
  const [pinThree, setPinThree] = useState('');
  const [pinFour, setPinFour] = useState('');
  
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  
  const handelChange = (e, nextInputRef) => {
    if(e.target.value.length === e.target.maxLength){
      nextInputRef.current.focus();
    }
  }
  
  const handelPrevious = (e, previousInputRef) => {
    if(e.key === "Backspace" && e.target.value.length === 0){
      previousInputRef.current.focus();
    }
  }
  
  const verifyAccount = (accountNo)=> {
    if(accountNo.length === 11){
      setReload(true);
      setTimeout(()=> {
        fetch(`https://${domain_name}/verify-bank-name.php`, {
          method: "POST",
          body: JSON.stringify({
            bank_code: 12345,
            account_no: accountNo,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(response => response.json())
        .then(data => {
          setReload(false);
          if(data.status == true){
            showMessage("success", data.msg);
            setAccName(data.account_name);
            setReciever(data.session_id);
          }else{
            showMessage("error", data.msg);
            setAccName("");
          }
        })
        .catch(err => {
          setReload(false);
          showMessage("error", "Server/Network error");
        });
      }, 1000);
    }
  }
  
  const handleAccNoChange = (e)=> {
    const val = e.target.value;
    setAccName("");
    setAccNo(val);
    verifyAccount(val);
  }
  
  const handleDisplay = () => {
    if(amount < 100){
      showMessage("error", "Minimum transfer amount is N100.00");
    }else if(accNo.length < 11){
      showMessage("error", "Invalid fluterpay recipient number");
    }else if(accName === ""){
      showMessage("error", "Please verify fluterpay recipient details before proceeding");
    }else{
       document.querySelector(".fixed-final").classList.toggle("active");
    }
  }
  
  const get_btn = document.getElementById("smtbtn");
  const [rawHtml, setRawHtml] = useState(false);
  
  const makePayment = ()=> {
    const pay_load = {
      user_id: user.unique_id,
      bank: "12345",
      bank_name: "Fluterpay",
      narration: "",
      account_no: accNo,
      account_name: accName,
      amount: amount,
      session_id: reciever,
      pin_one: pinOne,
      pin_two: pinTwo,
      pin_three: pinThree,
      pin_four: pinFour,
    };
    
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`${protocol}//${domain_name}/make-transfer.php`, {
        method: "POST",
        body: JSON.stringify(pay_load),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "SUBMIT", false, '1');
        if(data.includes("<table>")){
          setRawHtml(true);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "SUBMIT", false, '1');
        showMessage("error", "Server/Network error");
      });
  }
  
  function goBack(){
    window.history.back();
  }
  
  return(
    <>
      <Activity />
      <div className="p-head">
        <i className="bi bi-chevron-left no" onClick={goBack}></i>
        <span>To Fluterpay </span>
      </div>
        
      <div className="margin">
        <div className="form-topic bottom-space"><i className="bi bi-send-fill"></i>Transfer to Fluterpay User</div>
        <div class="green-msg">Instant, Zero issues, Fees.</div>
        <div className="form-design">
          <div className="form-topic">Recipient Account</div>
          
          <div className="inputs">
            <span>Fluterpay phone number</span>
            <input
            type="tel"
            id="account-no"
            value={accNo}
            onInput={handleAccNoChange}
            />
            <i className="bi bi-person-fill"></i>
          </div>
          
          <div className="form-small-topic">* Must contain fluterpay user phone number. *</div>
          <div className="form-small-topic">* Minimum digits is 10  *</div>
          <div className="form-small-topic" style={{marginBottom: '15px'}}>* Dont know the recipient's fluterpay phone number?</div>
          
          {accName !== "" ? (
          <>
            <div className="flex-btn white">
              <i className="bi bi-person-fill no"></i>
              <div>
                <b>{accName}</b>
                <small>{accNo}</small>
              </div>
            </div>
            
            <div className="inputs">
              <span>Amount</span>
              <input
              type="tel"
              id="amount"
              value={amount}
              placeholder="1000"
              onInput={(e) => setAmount(e.target.value)}
              />
            </div>
            <div className="form-small-topic">Minimum transfer amount is &#x20a6;100</div>
            <div className="form-small-topic bottom-space">Maximum transfer amount is &#x20a6;20,000</div>
            
            <button type="button" className="form-button" onClick={handleDisplay}>Proceed</button>
          </>
          ) : ""}
          
        </div>
        
        
      </div>
      
          <div className="fixed-final">
            <div className="f-f-box">
              <div className="f-f-tp seperate bottom-space">
                <span>Confirm Transaction PIN</span>
                <i className="bi bi-x no" onClick={handleDisplay}></i>
              </div>
              <div className="f-f-topic">Your about to make payment of &#x20a6;{amount} to {accName}({accNo})</div>
            
            <div className="f-inputs">
              <input 
                type="tel" 
                id="pin-1"
                maxLength="1"
                ref={input1Ref}
                onKeyUp={(e) => handelChange(e, input2Ref)}
                onChange={(e) => setPinOne(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-2"
                maxLength="1"
                ref={input2Ref}
                onKeyUp={(e) => handelChange(e, input3Ref)}
                onKeyDown={(e) => handelPrevious(e, input1Ref)}
                onChange={(e) => setPinTwo(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-3"
                maxLength="1"
                ref={input3Ref}
                onKeyUp={(e) => handelChange(e, input4Ref)}
                onKeyDown={(e) => handelPrevious(e, input2Ref)}
                onChange={(e) => setPinThree(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-4"
                maxLength="1"
                ref={input4Ref}
                onKeyDown={(e) => handelPrevious(e, input3Ref)}
                onChange={(e) => setPinFour(e.target.value)}
              />
            </div>
            <div className="f-forgot">Forgot Payment PIN, <Link to="/settings"> Reset?</Link></div>
            
            <button className="form-button reduce-height" id="smtbtn" onClick={makePayment}>SUBMIT</button>
            </div>
          </div>
      
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
      
      {rawHtml ? (
      <div className="fixed-success">
         <div className="f-success-i">
           <i className="bi bi-check-circle-fill"></i>
         </div>
         
         <div className="f-success-topic">Transfer Successful</div>
         <div className="f-success-small-topic">Congratulations! your transfer of &#x20a6;{amount} to {accName} of Fluterpay was successful.</div>
         <div className="f-success-seperate">
           <Link to="/reciept"><button className="f-success-seperate-btn">
             <i className="bi bi-share-fill"></i>
             Reciept
           </button></Link>
           <Link to="/dashboard"><button className="f-success-seperate-btn">
             <i className="bi bi-house-fill"></i>
             Dashboard 
           </button></Link>
         </div>
         <div className="green-msg">Congratulations 🎉, you have just earned 2%({amount}) cashback.</div>
         
         <div className="f-success-grow"></div>
         
         <Link to="/dashboard"><button className="form-button blue-bg">DISMISS</button></Link>
      </div>
      ) : ""}
    </>
  );
}

export default UserTransfer;