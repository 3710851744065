import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/account.css'
import avatar from'../images/avatar.png'
import logo from'../logos/logo2.png'

function Account(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
  
  function copyText(text) {
    navigator.clipboard.writeText(text).then(
      () => {
        showMessage("success", "Copy to clipboard");
      },
      (err) => {
        showMessage("error", "Failed to copy text");
      }
    );
  }
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';

  
  
  return (
    <>
        <div class="s-bal">
          <small>Total Balance</small>
          <b>&#x20a6;{user.bal}</b>
          <div class="csh-bk">& Cashback &#x20a6;{user.cashback_bal}</div>
        </div>
        
        <div class="security">
          <span><b>Security check is not turned on</b> Make sure your account is more secured with extra safety checks.</span>
          <button>Turn On</button>
        </div>
        
        <div class="white-pad">
          <Link to="/settings"><div class="each">
            <i class="bi bi-gear"></i>
            <div class="each-l">
              <b>Account Setting</b>
              <span>Manage your account</span>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></Link>
          <Link to="/account-verification"><div class="each">
            <i class="bi bi-check2-circle"></i>
            <div class="each-l">
              <b>Account Verification</b>
              <span>Upgrade/verify your account/identity</span>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></Link>
          <Link to="/transactions"><div class="each">
            <i class="bi bi-clock"></i>
            <div class="each-l">
              <b>Transaction History</b>
              <span>View your transaction history</span>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></Link>
          <Link to="/profile"><div class="each">
            <i class="bi bi-person"></i>
            <div class="each-l">
              <b>Profile</b>
              <span>Update your profile details</span>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></Link>
        </div>
        
        <div class="white-pad">
          <a href="https://docs.fluterpay.ng"><div class="each">
            <i class="bi bi-code-slash"></i>
            <div class="each-l">
              <b>Developer Api</b>
              <span>Easily sell our services via Api Connection</span>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></a>
          <Link to="/dashboard/support"><div class="each" id="nav:4">
            <i class="bi bi-headset" id="nav:4"></i>
            <div class="each-l" id="nav:4">
              <b id="nav:4">Support</b>
              <span id="nav:4">Reach out to us seamsellsy</span>
            </div>
            <i class="fas fa-chevron-right" id="nav:4"></i>
          </div></Link>
          <Link to="/logout"><div class="each">
            <i class="bi bi-lock"></i>
            <div class="each-l">
              <b>Log Out</b>
            </div>
            <i class="fas fa-chevron-right"></i>
          </div></Link>
        </div>
        
    </>
  );
}

export default Account;