import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import showMessage from './showMessage';
import authenticate from './Auth';
import checkValue from './inputError';
import '../css/log-sign.css';
import logo from '../logos/logo.png';

function Login() {  
  const [email, setEmail] = useState('');
  const [password, setPassord] = useState('');
  
  const [myJson, setJson] = useState({});
  
  const navigate = useNavigate();
  const get_btn = document.getElementById("smtbtn");
  
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const submitForm = (e) => {
    e.preventDefault();
    if(email === ""){
      showMessage("error", "Please enter email");
    }else if(password.length < 8){
      showMessage("error", "Invalid password minimum length 8 digits");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`https://${domain_name}/login.php`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        showP(get_btn, "Sign In", false, '1');
        if(data.status === true){
          showMessage("success", data.msg);
          sessionStorage.setItem("user", JSON.stringify(data.user));
          sessionStorage.setItem("verification", JSON.stringify(data.verification));
          setJson(data.user);
          setTimeout(() => {
            navigate('/dashboard');//user navigate to redirect
          }, 3000);
        }else{
          showMessage("error", data.msg);
        }
      })
      .catch(err => {
        showP(get_btn, "Sign In", false, '1');
        showMessage("error", "Server/Network error");
      });
    }
  }
  
  const handleEye = () => {
    const pswd = document.getElementById("password");
    const eye = document.getElementById("eye");
    
    if(pswd.type == "password"){
      pswd.type = "text";
      eye.className = "bi bi-eye-slash";
    }else{
      pswd.type = "password";
      eye.className = "bi bi-eye";
    }
  }
  
  
  return (
  <div className="body">
    <form className="form" onSubmit={submitForm}>
      <div className="header">
        <img src={logo} alt="Logo" />
        <span>Get Access to your Account</span>
      </div>
      
      <div className="inputs" id="red:email">
       <span>Email or Phone number</span>
       <i class="bi bi-person"></i>
       <input 
         type="text" 
         id="email" 
         placeholder="jhondoe@gmail.com"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
       />
       {<checkValue errMsg="Invalid email" />}
      </div>
      <div className="inputs">
       <span>Password</span>
       <i class="bi bi-lock"></i>
       <input 
         type="password" 
         id="password"
         placeholder="********"
         value={password}
         onChange={(e) => setPassord(e.target.value)}
        />
        <i className="bi bi-eye" id="eye" onClick={handleEye}></i>
      </div>
      
      <div className="links tp seperate">
        <label class="re" for="remember">
          <input type="checkbox" id="remember" name="remember" />
          Remember Me
        </label>
        <span><Link to="/forgot-password">Forgot password</Link></span>
      </div>
      
      <button type="submit" id="smtbtn" className="form-button">Sign In</button>
      
      <div className="links center">
        <span><Link to="/signup">Create Account</Link></span>
      </div>
    </form>
  </div>
  );
}

export default Login;