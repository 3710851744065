import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from '../showMessage'
import '../../css/change.css'

function EmailV(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  function goBack(){
    window.history.back();
  }
  
  const get_verify = sessionStorage.getItem("verification");
  const verify = JSON.parse(get_verify);
  
  
  const [phone, setPhone] = useState(user.email);
  const [otp, setOtp] = useState('');
  const [sentCode, setSentCode] = useState('');
  const [otpSent, setOtpSent] = useState(false);
 
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  function sendPasscode(e){
      showP(e.target, "Sending code . . .", true, "0.5");
      fetch(`https://${domain_name}/send-phone-code.php`, {
        method: "POST",
        body: JSON.stringify({
          type: "verify",
          phone: phone,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(e.target, "Resend passcode", false, "1");
        alert(data);
      })
      .catch(err => showMessage("error", "Server/Network error"));
  }
  
 window.addEventListener("DOMContentLoaded", ()=> {
   sendPasscode();
 });
  
  const updateEmail = (e) => {
    e.preventDefault();
    const g_btn = document.getElementById("smtbtn");
    if(otp === ""){
      showMessage("error", "Please enter otp code");
    }else if(parseInt(otp) !== parseInt(sentCode)){
      showMessage("error", "Incorrect otp code check and try again");
    }else{
      showP(g_btn, "<div class='roll'v</div>", true, "0.5");
      fetch(`${protocol}//${domain}:7070/vtu-app/src/server/update-email-v.php`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          phone: phone,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(g_btn, "VERIFY", false, "1");
        if(data.includes("success")){
          showMessage("success", data + ", redirecting...");
          verify.phone = phone;
          sessionStorage.setItem("verification", JSON.stringify(verify));
          setTimeout(()=> {
            goBack();
          }, 2000);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showMessage("error", "Server/Network error");
      })
    }
  }
  
  return(
    <div className="fixed-form active">
      <form className="f-form" onSubmit={updateEmail}>
        <div className="sep seperate">
          <b>Phone Verification</b>
          <i className="bi bi-x no" onClick={goBack}></i>
        </div>
        
          <input 
            type="number"
            id="phone"
            placeholder="08012345678" 
            hidden="true"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          
        <div className="inputs no-bottom">
          <span>One Time Password(OTP)</span>
          <input 
            type="number"
            id="otp"
            placeholder="123456" 
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        <button className="color-button" onClick={sendPasscode}>Send Passcode</button>
        
        <button type="submit" id="smtbtn" class="form-button reduce-height">VERIFY</button>
      </form>
    </div>
  );
}

export default EmailV;