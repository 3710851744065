import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import showMessage from './showMessage';
import checkValue from './inputError';
import '../css/log-sign.css';

function ForgotPassword() {  
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  let otpSent = false;
  const navigate = useNavigate();
  const get_btn = document.getElementById("smtbtn");
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const submitForm = () => {
    if(email == ""){
      showMessage("error", "Please enter email");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`https://${domain_name}/send-reset-code.php`, {
        method: "POST",
        body: JSON.stringify({
          type: "reset",
          email: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        showP(get_btn, "SEND CODE", false, '1');
        if(data.msg.includes("INBOX")){
          showMessage("success", data.msg);
          sessionStorage.setItem("reset-code", data.code);
          otpSent = true;
          showBox();
        }else{
          showMessage("error", data.msg);
          otpSent = false;
        }
      })
      .catch(err => showMessage("error", "Server/Network error"));
    }
  }
  
  const handleEye = () => {
    const pswd = document.getElementById("password");
    const eye = document.getElementById("eye");
    
    if(pswd.type == "password"){
      pswd.type = "text";
      eye.className = "bi bi-eye-slash";
    }else{
      pswd.type = "password";
      eye.className = "bi bi-eye";
    }
  }
  
  const showBox = () => {
    if(otpSent !== false){
      document.querySelector(".fixed-otp-box").classList.toggle("active")
    }
  }
  
  const getBtnTwo = document.getElementById("smtbtntwo");
  const updatePassword = () => {
    const sentCode = sessionStorage.getItem("reset-code");
    if(otp !== sentCode){
      showMessage("error", "Invalid otp code check code and try again");
      alert(sentCode);
    }else{
      showP(getBtnTwo, "Submitting . . .", true, '0.5');
      fetch(`https://${domain_name}/reset-password.php`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          code: otp,
          password: newPassword,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(getBtnTwo, "SUBMIT", false, '1');
        if(data.includes("success")){
          showMessage("success", data);
          navigate('/login');
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => showMessage("error", "Server/Network error"));
    }
  }
  
  
  return (
  <div className="body">
    <div className="form">
      <div className="header">
        <h2>Forgot Password</h2>
        <span>Don't worry! It happens. Kindly enter the email address linked with your account</span>
      </div>
      
      <div className="inputs" id="red:email">
       <span>Email</span>
       <i class="bi bi-envelope"></i>
       <input 
         type="email" 
         id="email" 
         placeholder="jhondoe@gmail.com"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
       />
      </div>
      
      <button type="button" id="smtbtn" className="form-button" onClick={submitForm}>SEND CODE</button>
      
      
      <button className="box-button" onClick={showBox}>Show OTP Input Box</button>
      
      
      
      <div className="fixed-otp-box">
        <div className="f-o-box">
          <div className="form-small-topic seperate bottom-space">
            <span>Reset Password</span>
            <i className="bi bi-x no red" onClick={showBox}></i>
          </div>
          
          <div className="inputs">
            <span>OTP Code</span>
            <i class="bi bi-phone"></i>
            <input 
             type="tel"
             id="otp-code"
             placeholder="123456"
             value={otp}
             onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          
          <div className="inputs">
            <span>New Password</span>
            <i class="bi bi-phone"></i>
            <input 
             type="password"
             id="password"
             placeholder="********"
             value={newPassword}
             onChange={(e) => setNewPassword(e.target.value)}
            />
            <i className="bi bi-eye" id="eye" onClick={handleEye}></i>
          </div>
          
          <button className="form-button" id="smtbtntwo" onClick={updatePassword}>SUBMIT</button>
        </div>
      </div>
      
      
      
      <div className="links center">
        <span>Remember Password <Link to="/login">Login?</Link></span>
      </div>
    </div>
  </div>
  );
}

export default ForgotPassword;