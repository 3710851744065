import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Activity = () => {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    const resetTimer = () => {
      setInactive(false);
      clearTimeout(window.inactivityTimeout);
      window.inactivityTimeout = setTimeout(() => setInactive(true), 5 * 60 * 1000);
    };

    resetTimer(); // Start the timer when the component mounts
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);

    return () => {
      clearTimeout(window.inactivityTimeout);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, []);

  return inactive ? <Navigate to="/logout" /> : null;
};

export default Activity;