import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/rewards.css'
import logo from'../logos/logo2.png'

function Rewards(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
  
  const [reload, setReload] = useState(false);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const checkIn = ()=> {
    setReload(true);
    fetch(`https://${domain_name}/check-in.php`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.unique_id,
      }),
      headers: {
          "Content-Type": "application/json",
      },
    })
    .then(response => response.text())
    .then(data => {
      setReload(false);
      if(data.includes("success")){
        showMessage("success", data);
      }else{
        showMessage("error", data)
      }
    })
    .catch(err => {
      setReload(false);
      showMessage("error", "Server/Network error " + err);
    });
  }
  
  const cashOut = (type)=> {
    setReload(true);
    fetch(`https://${domain_name}/cash-out.php`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.unique_id,
        type: type,
      }),
      headers: {
          "Content-Type": "application/json",
      },
    })
    .then(response => response.text())
    .then(data => {
      setReload(false);
      if(data.includes("success")){
        showMessage("success", data);
      }else{
        showMessage("error", data)
      }
    })
    .catch(err => {
      setReload(false);
      showMessage("error", "Server/Network error " + err);
    });
  }
  
  return(
    <>
      <div class="sep-btn-re">
        <div class="c-left">
          <span>Daily Check-In Balance</span>
          <b>&#x20a6;{user.check_in_bal}</b>
        </div>
        <div class="c-right">
          <span>Cashback Balance</span>
          <b>&#x20a6;{user.cashback_bal}</b>
        </div>
      </div>
      
      
      <div class="cash-box">
         <div class="l">
            <small>Check-In Balance</small>
            <b>&#x20a6;{user.check_in_bal}</b>
            <span><i class="fas fa-minus-circle"></i> &#x20a6;0.00 Today</span>
         </div>
         <div>
            <button id="cash-btn" onClick={checkIn}>Check In</button>
         <button onClick={()=> cashOut("check-in")}><i class="bi bi-wallet"></i></button>
         </div>
      </div>
      
      <div class="cash-box">
         <div class="l">
            <small>Cashback Balance</small>
            <b>&#x20a6;{user.cashback_bal}</b>
            <span><i class="fas fa-minus-circle"></i> &#x20a6;0.00 Today</span>
         </div>
         <button onClick={()=> cashOut("cashback")}>Cash Out <i class='fas fa-arrow-right'></i></button>
      </div>
 
      <div className="d-t">Daily Bonus</div>

      <div className="cash-box-two">
        <i className="bi bi-phone no"></i>
        <div>
          <b>MTN/Airtel Airtime 🎁 <b>+2%</b></b>
          <span>Buy Airtime and get up to <b>6%</b> Cashback</span>
        </div>
        <Link to="/airtime"><button>Go</button></Link>
      </div>
      
      <div className="cash-box-two">
        <i className="bi bi-wifi no"></i>
        <div>
          <b>MTN/Airtel Data 🎁 <b>+2%</b></b>
          <span>Buy Data and get up to <b>6%</b> Cashback</span>
        </div>
        <Link to="/data"><button>Go</button></Link>
      </div>
      
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
    </>
  );
}

export default Rewards;