import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/support.css'

function Support(){
  return (
    <>
     <div className="form-topic top-space">Let's Know How We Can Help You</div>
     
     <div className="support-btn">
       <i className="bi bi-chat"></i>
       <div className="s-left">
         <b>Chat</b>
         <span>Chat with us online</span>
       </div>
     </div>
     
     <div className="support-btn">
       <i className="bi bi-facebook"></i>
       <div className="s-left">
         <b>Facebook</b>
         <span>Send us message via facebook</span>
       </div>
     </div>
     
     <div className="support-btn">
       <i className="bi bi-whatsapp"></i>
       <div className="s-left">
         <b>WhatsApp</b>
         <span>Send us message via whatsapp</span>
       </div>
     </div>
    </>
  );
}

export default Support;