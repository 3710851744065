import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/home.css'
import logo from'../logos/logo2.png'


function Home(){
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [trans, setTrans] = useState([]);
  const [reload, setReload] = useState(false);
  
const reloadT = ()=> {
    setReload(true);
    fetch(`https://${domain_name}/transactions.php`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.unique_id,
        limit: 3,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(data => {
      setReload(false);
      if(data.status == true){
        setTrans(data.transactions);
        sessionStorage.setItem("user", JSON.stringify(data.user));
      }else{
        setReload(false);
        showMessage("error", data.msg);
      }
    })
    .catch(err => {
      setReload(false);
      showMessage("error", "Unable to fetch transactions");
    });
}
  
 useEffect(() => {
   reloadT();
 }, []);
  
  const handleEye = () => {
    const eye = document.getElementById("eye");
    const balance = document.getElementById("balance");
    
    if(eye.className == "bi bi-eye"){
      eye.className = "bi bi-eye-slash";
      balance.innerText = "NGN *******";
    }else{
      eye.className = "bi bi-eye";
      balance.innerText = user.format_balance;
    }
  }
  
 useEffect(() => {
    const slider = document.querySelector(".slider");
    const slides = document.querySelectorAll(".slider .slide");
    let currentIndex = 0;

    const slideInterval = setInterval(() => {
      currentIndex = (currentIndex + 1) % slides.length;
      slider.scrollTo({
        left: slides[currentIndex].offsetLeft,
        behavior: 'smooth'
      });
    }, 5000);

    return () => clearInterval(slideInterval);
  }, []);
  
  
  return (
    <>
    <div className="marq">
        <i className="fas fa-volume-up"></i>
        <marquee behavior="scroll" direction="left" scrollamount="5">
            NOT YET COMPLETE ACCOUNT VERIFICATION COMPLETE NOW TO ENJOY ALL OUR SERVICES WITHOUT RESTRICTION!
        </marquee>
    </div>
    <div className="dashboard">
      <div className="d-left">
        <span>Wallet Balance <i className="bi bi-eye" id="eye" onClick={handleEye}></i></span>
        <b id="balance">{user.format_balance}</b>
      </div>
      <Link to="/fund-wallet"><div className="d-right">
        <i className="bi bi-plus-circle-fill"></i>
        <span>Add Money</span>
      </div></Link>
    </div>
      
      <div className="transaction">
        <div className="t-topic">Recent Transactions <Link to="/transactions"><button>see all</button></Link></div>
        {trans.length > 0 ? (
        trans.slice(-3).map(newT => {
            let iconClass = "";
            if(newT.type.includes("Airtime")){
              iconClass = "bi bi-telephone-fill";
            }else if(newT.type.includes("Data")){
              iconClass = "bi bi-phone-fill";
            }else if(newT.type.includes("Account Credited")){
              iconClass = "bi bi-arrow-down-circle-fill";
            }else if(newT.type.includes("Transfer")){
              iconClass = "bi bi-bank";
            }else if(newT.type.includes("Cable Tv")){
              iconClass = "bi bi-tv-fill";
            }else if(newT.type.includes("Electricity")){
              iconClass = "ri ri-lightbulb-fill";
            }else if(newT.type.includes("Betting")){
              iconClass = "ri ri-football-fill";
            }else{
              iconClass = "bi bi-arrow-up-circle-fill";
            }
        
            return (
             <Link to={`/reciept?ref=${newT.reference}`}><div key={newT.id} className="transaction-box">
               <i className={iconClass}></i>
               <div className="t-left">
                 <span>{newT.type}</span>
                 <b>{newT.date}</b>
               </div>
               <div className="t-right">
                 <b className={newT.status}>{newT.status}</b>
                 <span>NGN {parseInt(newT.amount).toLocaleString()}</span>
               </div>
             </div></Link>
            );
          })
        ) : (
         <div className="empty-box">
           {
          //  <i className="bi bi-info-square"></i>
           }
           <span>No transaction found for this account, make a transaction now.</span>
           <button class="reload-button" onClick={reloadT}><i class="bi bi-arrow-repeat no"></i> reload</button>
         </div>
        )}
      </div>
      
      <div class="sep-btn">
        <Link to="/to-fluterpay"><button>
          <i class="bi bi-send-fill"></i>
          To Fluterpay
        </button></Link>
        <Link to="/to-bank"><button>
          <i class="bi bi-bank"></i>
          To Bank
        </button></Link>
        <Link to="/fund-wallet"><button>
          <i class="bi bi-plus-square-fill"></i>
          Addd Money
        </button></Link>
      </div>
    
    
    <div className="grids">
      
      <div className="service">
        <b className="s-topic">Quick Action</b>
        
        <div className="s-grid">
          <Link to="/airtime">
          <div className="s-service-box">
            <i className="ri ri-phone-fill"></i>
            <span>Airtime</span>
          </div>
          </Link>
          <Link to="/data">
          <div className="s-service-box">
            <i className="ri ri-smartphone-fill"></i>
            <span>Data</span>
          </div>
          </Link>
          <Link to="/cable">
          <div className="s-service-box">
            <i className="ri ri-tv-fill"></i>
            <span>Cable Tv</span>
          </div>
          </Link>
          <Link to="/electricity">
          <div className="s-service-box">
            <i className="ri ri-lightbulb-fill"></i>
            <span>Electricity</span>
          </div>
          </Link>
          <Link to="/betting">
          <div className="s-service-box">
            <i className="ri ri-football-fill"></i>
            <span>Betting</span>
          </div>
          </Link>
          <Link to="/exam">
          <div className="s-service-box">
            <i className="ri ri-graduation-cap-fill"></i>
            <span>Exam PIN</span>
          </div>
          </Link>
          <Link to="/bulk-sms">
          <div className="s-service-box">
            <i className="bi bi-chat-fill"></i>
            <span>Bulk SMS</span>
          </div>
          </Link>
          <Link to="/dashboard/services">
          <div className="s-service-box">
            <i className="bi bi-grid-fill"></i>
            <span>More</span>
          </div>
          </Link>
        </div>
      </div>
      
      </div>
     <div className="slider">
       <div className="slide">
         <b>Get Airtime at 1% Off</b>
         <Link to="/airtime"><button>Buy Now</button></Link>
       </div>
       <div className="slide">
         <b>Not yet complete account verification</b>
         <Link to="/account-verification"><button>Complete</button></Link>
       </div>
       <div className="slide">
         <b>Need access to settings</b>
         <Link to="/settings"><button>Open Here</button></Link>
       </div>
       <div className="slide">
         <b>Having problem finding all services</b>
         <Link to="/dashboard/services"><button>View Here</button></Link>
       </div>
    </div>
    
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
    </>
  );
}

export default Home;