// src/webauthn.js
import { startAuthentication } from '@simplewebauthn/browser';

// Example stored credential, replace this with actual data in a real application
const storedCredential = {
  id: 'exampleCredentialId',
  rawId: btoa('exampleRawId'), // Use Base64 to encode the rawId
  response: {
    clientDataJSON: btoa('exampleClientDataJSON'), // Use Base64 to encode the clientDataJSON
  },
};

 const authenticate = () => {
  if (!storedCredential) {
    return Promise.reject(new Error('No stored credential found'));
  }

  const opts = {
    publicKey: {
      challenge: Uint8Array.from(atob(storedCredential.response.clientDataJSON), c => c.charCodeAt(0)),
      allowCredentials: [{
        id: Uint8Array.from(atob(storedCredential.rawId), c => c.charCodeAt(0)),
        type: 'public-key',
        transports: ['usb', 'nfc', 'ble', 'internal'],
      }],
    },
  };

  return startAuthentication(opts);
};


export default authenticate;