import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import avatar from '../images/avatar.png'
import showMessage from './showMessage'
import '../css/profile.css'
import Activity from './includes/activity';

function Profile(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
  
  const genders = [
    {
      'id': 'Male',
      'name': 'Male'
    },
    {
      'id': 'Female',
      'name': 'Female'
    }
    ];
    
    const [genderOpen, setGenderOpen] = useState(false);
    const toggleGender = () => {
      setGenderOpen(!genderOpen);
    }
    
    
    const [fname, setFname] = useState(user.fname);
    const [lname, setLname] = useState(user.lname);
    const [state, setState] = useState(user.state);
    const [city, setCity] = useState(user.city);
    const [gender, setGender] = useState(user.gender);
    const [address, setAddress] = useState(user.address);
    
    
    const updateProfile = (e) => {
      e.preventDefault();
      if(fname.length < 3){
        showMessage("error", "Please enter a valid first name");
      }else if(lname.length < 3){
        showMessage("error", "Please enter a valid last name");
      }else if(state === ""){
        showMessage("error", "Please select or enter state");
      }else if(city === ""){
        showMessage("error", "Please enter city");
      }else if(gender === ""){
        showMessage("error", "Please select gender");
      }else if(address === ""){
        showMessage("error", "Please enter address");
      }else{
        
      }
    }
    
  return (
   <>
     <Activity />
     <div class="top-fixed p-head">
       <i className="bi bi-chevron-left no"></i>
       <span>Profile</span>
     </div>
   <div className="margin">
     <form className="form-design no-bg no-padding top-space" onSubmit={updateProfile}>
       
       <div className="p-box">
         <img src={avatar} alt="Avatar Image" />
         <button type="button">Change Picture</button>
       </div>
       
       <div className="b-text">NOTE: Field with asterisk(*) are editable</div>
       
       <div className="two-input">
         <div className="inputs">
           <span>First Name</span>
           <input
             type="text"
             id="first-name" 
             placeholder="Jhon" 
             value={fname}
             onChange={(e) => setFname(e.target.value)}
           />
         </div>
         <div className="inputs">
           <span>Last Name</span>
           <input
             type="text"
             id="last-name" 
             placeholder="Doe" 
             value={lname}
             onChange={(e) => setLname(e.target.value)}
           />
         </div>
       </div>
       
       <div className="inputs disabled">
         <span>Email</span>
         <input
           type="email"
           id="email" 
           placeholder="jhondoe@gmail.com" 
           disabled="true"
           value={user.email}
         />
       </div>
       
       <div className="inputs disabled">
         <span>Phone Number</span>
         <input 
           type="number" 
           id="phone" 
           placeholder="08012345678"
           disabled="true"
           value={user.phone}
         />
       </div>
       
       <div className="two-input">
         <div className="inputs">
           <span>State</span>
           <input 
             type="text" 
             id="state"
             placeholder="Niger, Lagos, Abuja"
             onChange={(e) => setState(e.target.value)}
           />
         </div>
         <div className="inputs">
           <span>City</span>
           <input
             type="text" 
             id="city"
             placeholder="Bida"
             onChange={(e) => setCity(e.target.value)}
           />
         </div>
       </div>
       
       <div className="inputs none">
         <span>Gender</span>
         <select
           id="gender"
           value={gender}
         >
           {genders.map(p => (
            <option value={p.id}>{p.name}</option>
           ))}
         </select>
       </div>
       
       <div className="inputs" onClick={toggleGender}>
         <span>Gender</span>
         <b>{gender || "-- Select Gender --"}</b>
         <i className={genderOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
         {genderOpen && (
           <ul>
             {genders.map(p => (
              <li onClick={() => {
                setGender(p.name);
              }}>{p.name}</li>
             ))}
           </ul>
         )}
       </div>
       
       <div className="inputs bottom-space">
         <span>Address</span>
         <input
           type="text"
           id="address" 
           placeholder="Lemu Road"
           value={address}
           onChange={(e) => setAddress(e.target.value)}
         />
       </div>
       
       <button type="submit" className="form-button">UPDATE</button>
     </form>
   </div>
   </> 
  );
}

export default Profile;