import React, { useState, useEffect, useRef } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/service.css'
import '../css/success-css.css'
import mtn from'../images/mtn.png'
import glo from'../images/glo.png'
import nMobile from'../images/9mobile.png'
import airtel from'../images/airtel.png'
import avatar from'../images/avatar.png'
import logo from'../logos/logo2.png'
import Activity from './includes/activity';

function Airtime(){
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [network, setNetwork] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  
  const [TouchStatus, setTouchStatus] = useState(true);
  
  const [ids, setIds] = useState([]);
  const [currentIds, setCurrentIds] = useState([]);
  
  const [pinOne, setPinOne] = useState('');
  const [pinTwo, setPinTwo] = useState('');
  const [pinThree, setPinThree] = useState('');
  const [pinFour, setPinFour] = useState('');
  
  const [getPlan, setGetPlan] = useState(false);
  
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  
  const handelChange = (e, nextInputRef) => {
    if(e.target.value.length === e.target.maxLength){
      nextInputRef.current.focus();
    }
  }
  
  const handelPrevious = (e, previousInputRef) => {
    if(e.key === "Backspace" && e.target.value.length === 0){
      previousInputRef.current.focus();
    }
  }
  
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get); 
  
  const get_btn = document.getElementById("smtbtn");
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const handelCancel = () => {
    window.history.back();
  }
  
  const submitForm = () => {
    if(network === ""){
      showMessage("error", "Please select network")
    }else if(phone.length < 11){
      showMessage("error", "Please enter a valid phone number required 11 digits")
    }else if(amount < 50){
      showMessage("error", "Please enter a valid amount minimum NGN100")
    }else{
      handleDisplay();
    }
  }
  
  function selectN(valueS, to_add_class_id){
    const network_g = document.getElementById("network");
  }
  
  const g_img = document.querySelectorAll(".img");
  const toggClass = () => {
    g_img.forEach(allImg => {
      allImg.classList.remove("active");
    });
    document.getElementById("network").dispatchEvent(new Event("change"));
  }
  
  const toggleDetails = () => {
    document.querySelector(".details").classList.toggle("active");
  }
  
  const handleDisplay = () => {
    document.querySelector(".fixed-final").classList.toggle("active");
  }
  
  const [rawHtml, setRawHtml] = useState(false);
  
  const buyAirtime = () => {
    const pay_load = {
      user_id: user.unique_id,
      network: network,
      network_name: name,
      phone: phone,
      amount: amount,
      pin_one: pinOne,
      pin_two: pinTwo,
      pin_three: pinThree,
      pin_four: pinFour,
    };
      setTouchStatus(false);
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`${protocol}//${domain_name}/airtime.php`, {
        method: "POST",
        body: JSON.stringify(pay_load),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "SUBMIT", false, '1');
        setTouchStatus(true);
        if(data.includes("success")){
          setRawHtml(true);
        }else{
          showMessage("error", data);
         // alert(data);
        }
      })
      .catch(err => {
        showP(get_btn, "SUBMIT", false, '1');
        setTouchStatus(true);
        showMessage("error", "Server/Network error");
      });
  }
  
  useEffect(()=> {
      fetch(`https://${domain_name}/get-data-plans.php`)
      .then(response => response.json())
      .then(data => {
        if(data.status !== true){
          showMessage("error", data.msg);
        }else{
          setIds(data.ids);
          setGetPlan(true);
        }
      })
      .catch(err => showMessage("error", "Unable to get data plans please reload "));
  }, []);
  
  useEffect(() => {
    const filteredIds = ids.filter(p => p.service === "AIRTIME" && p.status === "On");
    
    setCurrentIds(filteredIds);
  }, [ids]);
  
  return (
    <>
      <Activity />
      <div className="top-fixed s-head">
        <i className="bi bi-chevron-left no" onClick={handelCancel}></i>
        <span>Buy Airtime</span>
        <img src={avatar} alt="Avatar image" onClick={toggleDetails} />
        
        <div className="details">
          <Link to="/dashboard"><button>
            <i className="bi bi-house no"></i>
            <span>Dashboard</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/profile"><button>
            <i className="bi bi-person no"></i>
            <span>Profile</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/settings"><button>
            <i className="bi bi-gear no"></i>
            <span>Settings</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/logout"><button className="red">
            <i className="bi bi-lock no"></i>
            <span>Logout</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
        </div>
      </div>
      
   <div className="s-service">
      <div className="form-design no-bg reduce-padding grid">
          
          <div className="green-msg">
            <i className="bi bi-gift-fill"></i>
            Bonus offer you'll earn instant 2% cashback of every airtime you top-up today on Fluterpay.
          </div>
          
        <div className="form-topic bottom-space"><i className="ri ri-phone-fill no"></i>Airtime Topup</div>
          
          <div className="select-btns">
            <span className="span">Select Network</span>
            
            <div className="imgs">
              {currentIds.map((p, index) => (
              <div className="img">
                <img src={
                p.provider === "MTN" 
                ? mtn 
                : p.provider === "GLO"
                ? glo
                : p.provider === "9MOBILE" 
                ? nMobile
                : p.provider === "AIRTEL"
                ? airtel : ""} alt="service images" onClick={(e)=> {
                const g_img = document.querySelectorAll(".img");
                setNetwork(p.provider_id); 
                setName(p.provider); 
                toggClass();
                g_img[index].classList.add("active"); }} />
                <span>{p.provider}</span>
              </div>
              ))}
          </div>
        </div>
          
          <div className="inputs none">
            <span>Select Network</span>
            <select 
              id="network"
              value={network}
              onChange={(e) => { 
                setName(e.target.options[e.target.selectedIndex].text);
                setNetwork(e.target.value);
              }}
            >
              <option value="">-- Select Network --</option>
              {currentIds.map(p => (
               <option value={p.provider_id}>{p.provider}</option>
              ))}
            </select>
          </div>
          
          <div className="inputs">
            <span>Phone Number</span>
            <i class="bi bi-phone"></i>
            <input
              type="tel"
              id="phone"
              placeholder="08012345678"
              maxLength="11"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          
          <div className="inputs">
            <span>Amount</span>
            <i class="bi bi-123"></i>
            <input 
              type="tel"
              id="amount"
              placeholder="1000" 
              value={amount}
              onChange={(e)=> {
                 setAmount(e.target.value);
              }}
            />
          </div>
         
          <div className="pay-method">
            <b>How would you like to pay?</b>
            
            <div className="p-select">
              <input type="checkbox" name="pay-wallet" id="pay-wallet" checked />
              <label for="pay-wallet">Pay with wallet balance({user.format_balance})</label>
            </div>
            <div className="p-select">
              <input type="checkbox" name="pay-other" id="pay-other" disabled />
              <label for="pay-other">Pay with wallet orher methods</label>
            </div>
          </div>
          
          <button className="form-button" onClick={submitForm}>PROCEED</button>
        
        
        
          
          <div className="fixed-final">
            <div className="f-f-box">
              <div className="f-f-tp seperate bottom-space">
                <span>Confirm Transaction PIN</span>
                <i className="bi bi-x no" onClick={handleDisplay}></i>
              </div>
              <div className="f-f-topic">Please enter your transaction PIN to confirm purchase of &#x20a6;{amount}({name}) airtime on {phone}</div>
            
            <div className="f-inputs">
              <input 
                type="tel" 
                id="pin-1"
                maxLength="1"
                ref={input1Ref}
                onKeyUp={(e) => handelChange(e, input2Ref)}
                onChange={(e) => setPinOne(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-2"
                maxLength="1"
                ref={input2Ref}
                onKeyUp={(e) => handelChange(e, input3Ref)}
                onKeyDown={(e) => handelPrevious(e, input1Ref)}
                onChange={(e) => setPinTwo(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-3"
                maxLength="1"
                ref={input3Ref}
                onKeyUp={(e) => handelChange(e, input4Ref)}
                onKeyDown={(e) => handelPrevious(e, input2Ref)}
                onChange={(e) => setPinThree(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-4"
                maxLength="1"
                ref={input4Ref}
                onKeyDown={(e) => handelPrevious(e, input3Ref)}
                onChange={(e) => setPinFour(e.target.value)}
              />
            </div>
            <div className="f-forgot">Forgot Payment PIN, <Link to="/settings"> Reset?</Link></div>
            
            <button className="form-button reduce-height" id="smtbtn" onClick={buyAirtime}>SUBMIT</button>
            </div>
          </div>
          
          
        </div>
      </div>
      {getPlan === false ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
      
      {rawHtml ? (
      <div className="fixed-success">
         <div className="f-success-i">
           <i className="bi bi-check-circle-fill"></i>
         </div>
         
         <div className="f-success-topic">Airtime Purchase Successful</div>
         <div className="f-success-small-topic">Congratulations! your Airtime purchase of &#x20a6;{amount} for {phone} was successful.</div>
         <div className="f-success-seperate">
           <Link to="/reciept"><button className="f-success-seperate-btn">
             <i className="bi bi-share-fill"></i>
             Reciept
           </button></Link>
           <Link to="/dashboard"><button className="f-success-seperate-btn">
             <i className="bi bi-house-fill"></i>
             Dashboard 
           </button></Link>
         </div>
         <div className="green-msg">Congratulations 🎉, you have just earned 2%({amount}) cashback.</div>
         
         <div className="f-success-grow"></div>
         
         <Link to="/dashboard"><button className="form-button blue-bg">DISMISS</button></Link>
      </div>
      ) : ""}
    </>
  );
}

export default Airtime;