import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import showMessage from "./showMessage";
import '../css/log-sign.css';

function Signup() {
  const navigate = useNavigate();
  
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const [accType, setAcctype] = useState('wallet');
  const [typeOpen, setTypeOpen] = useState(false);
  const [bsName, setBsname] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pin, setPin] = useState('');
  const [password, setPassword] = useState('');
  const [referral, setReferral] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const id = queryParam.get("ref");
  
  const get_btn = document.getElementById("smtbtn");
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const submitForm = () => {
    if(accType === "wallet" && fname.length < 3){
      showMessage("error", "Invalid firstname minimum 3 letters");
    }else if(accType === "wallet" && lname.length < 3){
      showMessage("error", "Please enter a valid lastname minimum 3 letters");
    }else if(accType === "business" && bsName.length < 3){
      showMessage("error", "Please enter a valid business name minimum 3 letters");
    }else if(!emailRegex.test(email)){
      showMessage("error", "Please enter a valid email");
    }else if(phone.length < 11 || phone.length > 11){
      showMessage("error", "Please enter a valid phone number required 11 digits");
    }else if(pin.length < 4 || pin.length > 4){
      showMessage("error", "Ple enter a vaid pin required 4 digits");
    }else if(password.length < 8){
      showMessage("error", "Please enter a vaid password minimum 8 digits");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`https://${domain_name}/server/signup.php`, {
        method: "POST",
        body: JSON.stringify({
          acc_type: accType,
          bs_name: bsName,
          fname: fname,
          lname: lname,
          email: email,
          phone: phone,
          pin: pin,
          password: password,
          referral: referral
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "Sign Up", false, '1');
        if(data.includes("success")){
          showMessage("success", data);
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "Sign Up", false, '1');
        showMessage("error", "Server/Network error");
      });
    }
  }
  
  const toggleType = ()=> {
    setTypeOpen(!typeOpen)
  }
  
  return (
    <div className="body">
    <form className="form">
      <div className="header">
        <h2>Signup</h2>
        <span>Let's create your fluterpay account for you</span>
      </div>
      
        <div className="inputs" onClick={toggleType}>
          <span>Account Type</span>
          <b>{accType || "-- Select Account Type --"}</b>
          <i class="bi bi-chevron-down"></i>
          {typeOpen && (
           <ul>
             <li class={accType == "wallet" ? "active" : ""} onClick={()=> {
               setAcctype("wallet");
               setTypeOpen(false);
               setBsname("");
             }}>Wallet</li>
             <li class={accType == "business" ? "active" : ""} onClick={()=> {
               setAcctype("business");
               setTypeOpen(false);
               setFname("");
               setLname("");
             }}>Business</li>
           </ul>
          )}
        </div>
        
        
     {accType === "wallet" ? (
      <div className="two-input">
        <div className="inputs">
         <span>Firstname</span>
         <i class="bi bi-person"></i>
         <input 
           type="text" 
           id="firstname" 
           placeholder="jhon"
           onChange={(e) => setFname(e.target.value)}
         />
        </div>
        <div className="inputs">
         <span>Lastname</span>
         <i class="bi bi-person"></i>
         <input 
           type="text" 
           id="lastname"
           placeholder="doe"
           onChange={(e) => setLname(e.target.value)}
         />
        </div>
      </div>
     ) : (
        <div className="inputs">
         <span>Business Name</span>
         <i class="bi bi-shop"></i>
         <input 
           type="text" 
           id="business-name"
           placeholder="Fluterpay Enterprise"
           onChange={(e) => setBsname(e.target.value)}
         />
        </div>
     )}
      
      
      <div className="inputs">
       <span>Email</span>
       <i class="bi bi-envelope"></i>
       <input 
         type="email" 
         id="email" 
         placeholder="jhondoe@gmail.com"
         onChange={(e) => setEmail(e.target.value)}
       />
      </div>
      
      
      <div className="inputs">
       <span>Phone Number</span>
       <i class="bi bi-telephone"></i>
       <input
         type="tel"
         id="phone" 
         placeholder="08012345678"
         onChange={(e) => setPhone(e.target.value)}
       />
      </div>
      
      
      <div className="inputs">
       <span>Pin</span>
       <i class="bi bi-phone"></i>
       <input 
         type="tel"
         maxLength="4"
         id="pin" 
         placeholder="1234" 
         onChange={(e) => setPin(e.target.value)}
       />
      </div>
      
      
      <div className="inputs">
       <span>Password</span>
       <i class="bi bi-lock"></i>
       <input 
         type="password"
         id="password"
         placeholder="********" 
         onChange={(e) => setPassword(e.target.value)}
       />
      </div>
      
      
      <div className="inputs">
       <span>Referral (Optional)</span>
       <i class="bi bi-person"></i>
       <input 
         type="text"
         id="referral"
         placeholder="28H7HK"
         value={id}
         onChange={(e) => setReferral(e.target.value)}
       />
      </div>
      
      
      <button className="form-button" id="smtbtn" type="button" onClick={submitForm}>Sign Up</button>
      
      <div className="links center">
        <span>Already have an account <Link to="/login">Login Now?</Link></span>
      </div>
    </form>
    </div>
  );
}

export default Signup;