import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/service.css'
import avatar from'../images/avatar.png'
import logo from'../logos/logo2.png'
import Activity from './includes/activity';

function Electricity(){
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const navigate = useNavigate();
  
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
  
  const [meter, setMeter] = useState('');
  const [meterName, setMeterName] = useState('');
  
  const [meterNo, setMeterNo] = useState('');
  const [meterNoName, setMeterNoName] = useState('');
  
  const [amount, setAmount] = useState('');
  
  const [ids, setIds] = useState([]);
  const [currentIds, setCurrentIds] = useState([]);
  
  const [reload, setReload] = useState(false);
  const [billerOpen, setBillerOpen] = useState(false);
  
  const [pinOne, setPinOne] = useState('');
  const [pinTwo, setPinTwo] = useState('');
  const [pinThree, setPinThree] = useState('');
  const [pinFour, setPinFour] = useState('');
  
  const handelChange = (e, nextInputRef) => {
    if(e.target.value.length === e.target.maxLength){
      nextInputRef.current.focus();
    }
  }
  
  const handelPrevious = (e, previousInputRef) => {
    if(e.key === "Backspace" && e.target.value.length === 0){
      previousInputRef.current.focus();
    }
  }
  
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  
  const toggleBiller = ()=> {
    setBillerOpen(!billerOpen);
  }
  
  const handelCancel = () => {
    window.history.back();
  }
  
  const toggleDetails = () => {
    document.querySelector(".details").classList.toggle("active");
  }
  
  const handleDisplay = () => {
    document.querySelector(".fixed-final").classList.toggle("active");
  }
 
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  useEffect(()=> {
    setReload(true);
      fetch(`https://${domain_name}/get-data-plans.php`)
      .then(response => response.json())
      .then(data => {
        setReload(false);
        if(data.status !== true){
          showMessage("error", data.msg);
        }else{
          setIds(data.ids);
        }
      });
  }, []);
  
  useEffect(() => {
    const filteredIds = ids.filter(p => p.service === "ELECTRICITY" && p.status === "On");
    
    setCurrentIds(filteredIds);
  }, [ids]);
  
  const get_btn_one = document.getElementById("vbtn");
  
  const verifyNo = ()=> {
      showP(get_btn_one, "<div class='roll'></div>", true, '0.5');
      fetch(`https://${domain_name}/verify-electricity.php`, {
        method: "POST",
        body: JSON.stringify({
          meter: meter,
          type: "1",
          meter_no: meterNo
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
      .then(data => {
        if(data.status === true){
          setMeterNoName(data.name);
          showMessage("success", "Cable name verification successful");
        }else{
          setMeterNoName('');
          showMessage("error", JSON.stringify(data));
        }
        showP(get_btn_one, "verify", false, '1');
      })
      .catch(err => {
        showP(get_btn_one, "verify", false, '1');
        showMessage("error", "Server/Network error " + err);
      });
  }
  
  const checkDetail = ()=> {
    if(meter === ""){
      showMessage("error", "Please select meter");
    }else if(meterNo === ""){
      showMessage("error", "Please enter meter number");
    }else if(amount < 1000){
      showMessage("error", "Invalid amount minimum recharge is N1,000");
    }else if(meterNoName === ""){
      showMessage("error", "Please verify meter name before proceeding");
    }else{
      handleDisplay();
    }
  }
  
  const get_btn = document.getElementById('smtBtn');
  
  const [rawHtml, setRawHtml] = useState(false);
  
  const payElectric = ()=> {
    const pay_load = {
      user_id: user.unique_id,
      meter: meter,
      meter_name: meterName,
      type: 1,
      type_name: "prepaid",
      meter_no: meterNo,
      meter_no_name: meterNoName,
      amount: amount,
      pin_one: pinOne,
      pin_two: pinTwo,
      pin_three: pinThree,
      pin_four: pinFour,
    };
    
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`${protocol}//${domain_name}/electricity.php`, {
        method: "POST",
        body: JSON.stringify(pay_load),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "SUBMIT", false, '1');
        if(data.includes("success")){
          setRawHtml(true);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "SUBMIT", false, '1');
        showMessage("error", "Server/Network error");
      });
  }
  
  return(
    <>
      <Activity />
      <div className="top-fixed s-head">
        <i className="bi bi-chevron-left no" onClick={handelCancel}></i>
        <span>Buy Electricity</span>
        <img src={avatar} alt="Avatar image" onClick={toggleDetails} />
        
        <div className="details">
          <Link to="/dashboard"><button>
            <i className="bi bi-house no"></i>
            <span>Dashboard</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/profile"><button>
            <i className="bi bi-person no"></i>
            <span>Profile</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/settings"><button>
            <i className="bi bi-gear no"></i>
            <span>Settings</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
          <Link to="/logout"><button className="red">
            <i className="bi bi-lock no"></i>
            <span>Logout</span>
            <i className="bi bi-chevron-right"></i>
          </button></Link>
        </div>
      </div>
      
   <div className="s-service">
      <div className="form-design no-bg reduce-padding grid">
        <div className="form-topic bottom-space"><i className="ri ri-lightbulb-line"></i>Electricity Topup</div>
        
        <div className="inputs" onClick={toggleBiller}>
          <span>Select Meter</span>
          <i className="bi bi-grid"></i>
          <b>{meterName || '-- Select Meter --'}</b>
          <i className="bi bi-chevron-down"></i>
          {billerOpen ? (
          <ul>
            {currentIds.map(p => (
            <li class={meter === p.provider_id ? "active" : ""}   onClick={(e)=> {
              setMeter(p.provider_id);
              setMeterName(p.provider);
            }}
            >{p.provider}({p.ab})</li>
            ))}
          </ul>
          ) : ""}
        </div>
        
        <div className="inputs">
          <span>Meter No</span>
          <i className="bi bi-person"></i>
          <input 
          type="number"
          id="electricity-no"
          placeholder="546790827"
          value={meterNo}
          onChange={(e) => setMeterNo(e.target.value)}
          />
          <button className="verify" id="vbtn" onClick={verifyNo}>verify</button>
        </div>
        
        <div className="inputs disabled">
          <span>Meter Name</span>
          <i className="bi bi-person"></i>
          <input 
          type="text"
          id="meter-name"
          placeholder="John Doe"
          disabled="true"
          value={meterNoName}
          />
        </div>
        
        <div className="inputs">
          <span>Amount</span>
          <i className="bi bi-123"></i>
          <input 
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        
          <div className="pay-method">
            <b>How would you like to pay?</b>
            
            <div className="p-select">
              <input type="checkbox" name="pay-wallet" id="pay-wallet" checked />
              <label for="pay-wallet">Pay with wallet balance({user.format_balance})</label>
            </div>
            <div className="p-select">
              <input type="checkbox" name="pay-other" id="pay-other" disabled />
              <label for="pay-other">Pay with wallet orher methods</label>
            </div>
          </div>
        
        <button type="button" className="form-button" onClick={checkDetail}>PROCEED</button>
        
          <div className="fixed-final">
            <div className="f-f-box">
              <div className="f-f-tp seperate bottom-space">
                <span>Confirm Transaction PIN</span>
                <i className="bi bi-x no" onClick={handleDisplay}></i>
              </div>
              <div className="f-f-topic">Please enter your transaction PIN to confirm the purchase of {meterName} to {meterNoName} at amount of {amount}</div>
            
            <div className="f-inputs">
              <input 
                type="tel" 
                id="pin-1"
                maxLength="1"
                ref={input1Ref}
                onKeyUp={(e) => handelChange(e, input2Ref)}
                onChange={(e) => setPinOne(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-2"
                maxLength="1"
                ref={input2Ref}
                onKeyUp={(e) => handelChange(e, input3Ref)}
                onKeyDown={(e) => handelPrevious(e, input1Ref)}
                onChange={(e) => setPinTwo(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-3"
                maxLength="1"
                ref={input3Ref}
                onKeyUp={(e) => handelChange(e, input4Ref)}
                onKeyDown={(e) => handelPrevious(e, input2Ref)}
                onChange={(e) => setPinThree(e.target.value)}
              />
              <input 
                type="tel" 
                id="pin-4"
                maxLength="1"
                ref={input4Ref}
                onKeyDown={(e) => handelPrevious(e, input3Ref)}
                onChange={(e) => setPinFour(e.target.value)}
              />
            </div>
            <div className="f-forgot">Forgot Payment PIN, <Link to="/settings"> Reset?</Link></div>
            
            <table>
              <tr>
                <td>Transaction Amount:</td>
                <td>&#x20a6;{amount}</td>
              </tr>
              <tr>
                <td>Discount:</td>
                <td>0%</td>
              </tr>
              <tr>
                <td>Cashback:</td>
                <td>2%</td>
              </tr>
            </table>
            
            <button className="form-button reduce-height" id="smtBtn" onClick={payElectric}>SUBMIT</button>
            </div>
          </div>
        
      </div>
   </div>
   
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
      
      {rawHtml ? (
      <div className="fixed-success">
         <div className="f-success-i">
           <i className="bi bi-check-circle-fill"></i>
         </div>
         
         <div className="f-success-topic">Electricity Purchase Successful</div>
         <div className="f-success-small-topic">Congratulations! your Electricity purchase of  {amount} to {meterNoName}({meterNo}) was successful.</div>
         <div className="f-success-seperate">
           <Link to="/reciept"><button className="f-success-seperate-btn">
             <i className="bi bi-share-fill"></i>
             Reciept
           </button></Link>
           <Link to="/dashboard"><button className="f-success-seperate-btn">
             <i className="bi bi-house-fill"></i>
             Dashboard 
           </button></Link>
         </div>
         <div className="green-msg">Congratulations 🎉, you have just earned 2%({amount}) cashback.</div>
         
         <div className="f-success-grow"></div>
         
         <Link to="/dashboard"><button className="form-button blue-bg">DISMISS</button></Link>
      </div>
      ) : ""}
    </>
  );
}

export default Electricity;