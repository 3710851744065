import React, { useState, useEffect } from 'react'
import { PaystackButton } from 'react-paystack'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/fund.css'
import '../css/change.css'
import logo from '../logos/logo2.png'
import Activity from './includes/activity';

function Fund(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  const [bankName, setBankName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accOpen, setAccOpen] = useState(false);
  const toggleAcc = ()=> {
    setAccOpen(!accOpen);
  }
  
  const [account, setAccount] = useState([]);
  const [reload, setReload] = useState(false);
  
  const getAcc = ()=> {
    setReload(true);
    fetch(`https://${domain_name}/get-account.php`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user.unique_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(data => {
      setReload(false);
      if(data.status == true){
        setAccount(data.account);
        sessionStorage.setItem("v_account", JSON.stringify(data.account));
      }else{
        showMessage("error", data.msg);
      }
    })
    .catch(err => {
      setReload(false);
      showMessage("error", "Unable to fetch transactions");
    });
  }
  
  useEffect(()=> {
      getAcc();
  }, []);
  
  const toggleGenerate = () => {
    document.getElementById("generate-form").classList.toggle("active");
  }
  const get_btn = document.getElementById("g-btn");
  const generateAcc = ()=>{
    if(accountType === ""){
      showMessage("error", "Please select account");
    }else{
      showP(get_btn, "<div class='roll'></div>", true, '0.5');
      fetch(`${protocol}//${domain_name}/generate-account.php`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          bank_name: bankName,
          bank_code: accountType,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(get_btn, "SUBMIT", false, '1');
        if(data.includes("success")){
          showMessage("success", data);
          toggleGenerate();
          setTimeout(()=> {
            getAcc();
          }, 1000);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(get_btn, "SUBMIT", false, '1');
        showMessage("error", "Server/Network error");
      });
    }
  }
  
  const available_banks = [
    {
      "id": '1058',
      "bank_name": "Bankly Bank",
    }
  ];
    
  function goBack(){
    window.history.back();
  }
  
  
  return (
    <>
      <Activity />
      <div className="p-head">
        <i className="bi bi-chevron-left no" onClick={goBack}></i>
        <span>Fund Account</span>
      </div>
      <div className="margin">
        
        <pre>
          
        </pre>
        <div className="marq">
            <i className="fas fa-volume-up"></i>
            <marquee behavior="scroll" direction="left" scrollamount="5">
                Note: Deposit on fluterpay may take up to 1 minute to reflect, Thank you for your patience.
            </marquee>
        </div>
        
        <div className="flex-btn">
          <i className="bi bi-bank no"></i>
          <div>
            <b>Bank Transfer</b>
            <small>Add Money via mobile or internet banking</small>
          </div>
        </div>
        
          {account.length > 0 ? (
          account.map(accD => {
            return (
              <div className="virtual-box">
                <div className="top-t">
                  <span>Virtual Account</span>
                  <b>{accD.bank_name}</b>
                </div>
                
                <div className="acc-no">
                  <b>{accD.acc_no}</b>
                  <i className="bi bi-copy no"></i>
                </div>
                
                <div className="text">{accD.acc_name}</div>
              </div>
            );
          })
          ) : (
           <div className="empty-box">
             <i className="bi bi-info-square"></i>
             <span>No virtual account found for this account </span>
             <button class="reload-button" onClick={getAcc}><i class="bi bi-arrow-repeat"></i> reload</button>
           </div>
          )}
        
        
        <div className="flex-btn white">
          <i className="bi bi-wallet-fill no"></i>
          <div>
            <b>Manual Funding</b>
            <small>Fund your account manual with payment methods, It's attract charges of 1.5%</small>
          </div>
          <i className="bi bi-chevron-right no"></i>
        </div>
        
      </div>
      
      <div className="fixed-g">
        <button type="button" className="form-button no-top reduce-height" onClick={toggleGenerate}>GENERATE ACCOUNT</button>
      </div>
      
      <div className="fixed-form" id="generate-form">
        <div className="f-form">
          <div className="sep seperate no">
            <b>Generate Account</b>
            <i className="bi bi-x no" onClick={toggleGenerate}></i>
          </div>
          
          <div className="inputs" onClick={toggleAcc}>
            <span>Select Account</span>
            <b>{bankName || '-- Select Account --'}</b>
            <i className="bi bi-chevron-down"></i>
            {accOpen ? (
            <ul>
              {available_banks.map(b => (
                <li onClick={()=> {
                  setAccountType(b.id);
                  setBankName(b.bank_name);
                }}>{b.bank_name}</li>
              ))}
            </ul>
            ) : ""}
          </div>
          
          <button type="buttton" id="g-btn" className="form-button reduce-height top-space" onClick={generateAcc}>GENERATE</button>
        </div>
      </div>
      
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
    </>
  );
}

export default Fund;