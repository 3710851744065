import React from 'react';
import ReactDOM from 'react-dom';
import Msg from './msg';

function showMessage(type, message) {
  const msgContainer = document.createElement('div');
  msgContainer.className = "mbx";
  document.body.appendChild(msgContainer);

  ReactDOM.render(<Msg type={type} message={message} />, msgContainer);

  // Automatically remove the message after 6 seconds
  setTimeout(() => {
    msgContainer.style.opacity = "0";
    setTimeout(() => {
       ReactDOM.unmountComponentAtNode(msgContainer);
       document.body.removeChild(msgContainer);
    }, 1000);
  }, 6000);
}

export default showMessage;