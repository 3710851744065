import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import '../css/services.css'

function Services(){
  return (
    <>
      <div className="form-topic top-space">Payments</div>
      <div className="form-small-topic bottom-space flex"><i className="bi bi-info-circle no"></i> Explore services that deals transactions and payments</div>
      
      <div className="g-services">
        <Link to="/airtime"><div className="s-service">
          <i className="ri ri-phone-fill"></i>
          <span>Airtime</span>
        </div></Link>
        <Link to="/data"><div className="s-service">
          <i className="ri ri-smartphone-fill"></i>
          <span>Data</span>
        </div></Link>
        <Link to="/electricity"><div className="s-service">
          <i className="ri ri-lightbulb-fill"></i>
          <span>Electricity</span>
        </div></Link>
        <Link to="/cable"><div className="s-service">
          <i className="ri ri-tv-fill"></i>
          <span>Cable Tv</span>
        </div></Link>
        <div className="s-service">
          <i className="ri ri-graduation-cap-fill"></i>
          <span>Exam Pin</span>
        </div>
        <div className="s-service">
          <i className="ri ri-phone-fill"></i>
          <span>Airtime Pin</span>
        </div>
        <div className="s-service">
          <i className="bi bi-phone-fill"></i>
          <span>Data Pin</span>
        </div>
        <div className="s-service">
          <i className="bi bi-lock-fill"></i>
          <span>Fund Lock</span>
        </div>
        <Link to="/payment-link"><div className="s-service">
          <i className="ri ri-link"></i>
          <span>Payment Link</span>
        </div></Link>
        <Link to="/fund-wallet"><div className="s-service">
          <i className="bi bi-plus-square-fill"></i>
          <span>Add Money</span>
        </div></Link>
      </div>
      
      <div className="form-topic top-space extra">Others</div>
      <div className="form-small-topic bottom-space"><i className="bi bi-info-circle no"></i> Explore other site services</div>
      
      <div className="g-services">
        <Link to="/fund-wallet"><div className="s-service">
          <i className="bi bi-bank"></i>
          <span>Virtual Accounts</span>
        </div></Link>
        <Link to="/transactions"><div className="s-service">
          <i className="bi bi-activity"></i>
          <span>Transactions</span>
        </div></Link>
        <Link to="/referrals"><div className="s-service">
          <i className="ri ri-group-fill"></i>
          <span>Referrals</span>
        </div></Link>
        <Link to="/settings"><div className="s-service">
          <i className="bi bi-gear-fill"></i>
          <span>Settings</span>
        </div></Link>
      </div>
    </>
  );
}

export default Services;