import React, { useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom'
import showMessage from '../showMessage'
import '../../css/change.css'

function BvnV(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
    
  const domain_name = process.env.REACT_APP_DOMAIN_NAME;
  
  function goBack(){
    window.history.back();
  }
  
  const get_verify = sessionStorage.getItem("verification");
  const verify = JSON.parse(get_verify);
  
  
  const [bvn, setBvn] = useState('');
  const [dob, setDob] = useState('');
 
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  function showP(putInn, txt, tOrf, op){
    putInn.innerHTML = txt;
    putInn.disabled = tOrf;
    putInn.style.opacity = op;
  }
  
  
  const updateBvn = (e) => {
    e.preventDefault();
    const g_btn = document.getElementById("smtbtn");
    if(bvn.length < 11){
      showMessage("error", "Please enter a valid BVN number");
    }else if(dob === ""){
      showMessage("error", "Please select date of birth");
    }else{
      showP(g_btn, "<div class='roll'v</div>", true, "0.5");
      fetch(`https://${domain_name}/update-bvn-v.php`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          bvn: bvn,
          dob: dob,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        showP(g_btn, "VERIFY", false, "1");
        if(data.includes("success")){
          showMessage("success", data + ", redirecting...");
          verify.bvn = bvn + '_verifying';
          sessionStorage.setItem("verification", JSON.stringify(verify));
          setTimeout(()=> {
            goBack();
          }, 2000);
        }else{
          showMessage("error", data);
        }
      })
      .catch(err => {
        showP(g_btn, "VERIFY", false, "1");
        showMessage("error", "Server/Network error");
      })
    }
  }
  
  const [isPending, setIsPending] = useState('')
  const bvn_no = verify.bvn;
  
  const [dis, setDis] = useState(false);
  
  useEffect(() => {
   if(bvn_no === ""){
     setIsPending("");
     setDis(false);
   }else if(bvn_no !== "" && !bvn_no.includes("_")){
     setDis(true);
     showMessage("error", "You have already completed your level 2 verification, redirecting......");
     setTimeout(()=> {
      window.history.back();
     }, 5000);
   }else if(bvn_no.includes("_verifying")){
     setIsPending("verifying");
     setDis(true);
   }else if(bvn_no.includes("_rejected")){
     setIsPending("rejected");
     setDis(false);
   }
  }, [bvn_no]);
  
  
  return(
    <div className="fixed-form active">
      <form className="f-form" onSubmit={updateBvn}>
        <div className="sep seperate">
          <b>Bvn Verification</b>
          <i className="bi bi-x no" onClick={goBack}></i>
        </div>
       {isPending === "verifying" ? (
          <div class="orange-msg">Your BVN verification is still under review, you will be sent an email notification when approved</div>
       ) : isPending === "rejected" ? (
          <div class="red-msg">Your BVN verification was reject due to some certain reasons, Please check your email for more information.</div>
       ) : ""}
       
        <div className={`inputs no-bottom ${dis ? "disabled" : ""}`}>
          <span>Bank Verification Number (BVN)</span>
          <input 
            type="tel"
            id="bvn"
            placeholder="01234567890" 
            value={dis ? verify.bvn.replace("_verifying", "") : bvn}
            disabled={dis}
            onChange={(e) => setBvn(e.target.value)}
          />
        </div>
        
        
        <div className={`inputs no-bottom ${dis ? "disabled" : ""}`}>
          <span>Date Of Birth (DOB)</span>
          <input 
            type="date"
            id="date"
            placeholder="2/11/2024" 
            value={dis ? verify.dob : dob}
            disabled={dis}
            onChange={(e) => setDob(e.target.value)}
          />
        </div>
        
        <button type="submit" id="smtbtn" class={`form-button reduce-height ${dis ? "disabled" : ""}`} disabled={dis}>VERIFY</button>
      </form>
    </div>
  );
}

export default BvnV;