import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'

function Logout(){
  sessionStorage.clear();
  return (
    <Navigate to="/login" />
  );
}

export default Logout;