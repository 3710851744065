import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, Navigate } from 'react-router-dom'
import showMessage from './showMessage'
import EmailV from './includes/email-verification'
import PhoneV from './includes/phone-verification'
import BvnV from './includes/bvn-verification'
import NinV from './includes/nin-verification'
import '../css/settings.css'
import '../css/change.css'
import Activity from './includes/activity';

function AccountVerification(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
  const lv = user.level;
  
  function goBack(){
    window.history.back();
  }
  
  const get_verify = sessionStorage.getItem("verification");
  const verify = JSON.parse(get_verify);
  
  
  return(
    <div class="margin">
      <Activity />
      <div class="top-fixed p-head">
        <i className="bi bi-chevron-left no" onClick={goBack}></i>
        <span>Account Verification</span>
      </div>
      
      <Routes>
        <Route path="email" element={<EmailV />} />
        <Route path="phone" element={<PhoneV />} />
        <Route path="bvn" element={<BvnV />} />
        <Route path="nin" element={<NinV />} />
      </Routes>
      {
      //for level zero 
      }
      <div class={`form-design top-space ${lv === "level-0" || lv === "level-1" || lv === "level-2" || lv === "level-3" ? "green-border" : "black-border"}`}>
          <div class="form-topic no-bottom">Level 0</div>
          <div class="form-small-topic"><b>Requirements:</b> Basic Account Creation</div>
          <div class="form-small-topic"><b>Income:</b> &#x20a6;20,000</div>
          <div class="form-small-topic"><b>Outcome:</b> &#x20a6;10,000</div>
          {lv === "level-0" || lv === "level-1" || lv === "level-2" || lv === "level-3" ? (
            <button class="form-button reduce-height green-bg">Completed</button>
          ) : (
            <button class="form-button">Updrade To Level 1</button>
          )}
      </div>
      
      
      {
      //for level one 
      }
      <div class={`form-design ${lv === "level-1" || lv === "level-2" || lv === "level-3" ? "green-border" : "black-border"}`}>
          <div class="form-topic no-bottom">Level 1</div>
          <div class="form-small-topic"><b>Requirements:</b> Email and Phone Number verification</div>
          <div class="form-small-topic"><b>Income:</b> &#x20a6;50,000</div>
          <div class="form-small-topic"><b>Outcome:</b> &#x20a6;20,000</div>
          {lv === "level-1" || lv === "level-2" || lv === "level-3" ? (
            <button class="form-button reduce-height green-bg">Completed</button>
          ) : (
            <Link to="/account-verification/email"><button class="form-button reduce-height">Updrade To Level 1</button></Link>
          )}
      </div>
      
      
      {
      //for level two 
      }
      <div class={`form-design ${lv === "level-2" || lv === "level-3" ? "green-border" : "black-border"}`}>
          <div class="form-topic no-bottom">Level 2</div>
          <div class="form-small-topic"><b>Requirements:</b> BVN</div>
          <div class="form-small-topic"><b>Income:</b> &#x20a6;500,000</div>
          <div class="form-small-topic"><b>Outcome:</b> &#x20a6;1,000,000</div>
          {lv === "level-2" || lv === "level-3" ? (
            <button class="form-button reduce-height green-bg">Completed</button>
          ) : verify.bvn.includes("_rejected") ? (
            <Link to="/account-verification/bvn"><button class="form-button reduce-height red-bg">Rejected, Re Submit Verification</button></Link>
          ) : lv === "level-0" ? (
            <button class="form-button reduce-height white-bg">Upgrade to level 1 first</button>
          ) : (
            <Link to="/account-verification/bvn"><button class="form-button reduce-height">Updrade To Level 2</button></Link>
          )}
      </div>
      
      
      {
      //for level two 
      }
      <div class={`form-design ${lv === "level-3" ? "green-border" : "black-border"}`}>
        <div class="form-topic no-bottom">Level 3</div>
        <div class="form-small-topic"><b>Requirements:</b> NIN</div>
        <div class="form-small-topic"><b>Income:</b> Unlimited</div>
        <div class="form-small-topic"><b>Outcome:</b> Unlimited</div>
          {lv === "level-3" ? (
            <button class="form-button reduce-height green-bg">Finish</button>
          ) : verify.bvn.includes("_rejected") ? (
            <Link to="/account-verification/nin"><button class="form-button reduce-height red-bg">Rejected, Re Submit Verification</button></Link>
          ) : lv === "level-0" ? (
            <button class="form-button reduce-height white-bg">Upgrade to level 1 first</button>
          ) : lv === "level-1" ? (
            <button class="form-button reduce-height white-bg">Upgrade to level 2 first</button>
          ) : (
            <Link to="/account-verification/nin"><button class="form-button reduce-height">Upgrade To Level 3</button></Link>
          )}
      </div>
      
    </div>
  );
}

export default AccountVerification;