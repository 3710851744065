import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import showMessage from './showMessage'
import html2pdf from 'html2pdf.js'
import '../css/reciept.css'
import logo from'../logos/logo2.png'

function Reciept(){
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  
  const navigate = useNavigate();
  
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
  
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const ref = queryParam.get("ref");
  
  const [reload, setReload] = useState(false);
  const [rawHtml, setRawHtml] = useState('');
  
  function shareR() {
        const element = document.body; // Use the actual DOM element

        // Generate the PDF
        html2pdf()
            .from(element)
            .set({ 
                margin: 1,
                filename: 'fluterpay_transaction_reciept.pdf',
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' } 
            })
            .save() // Save the PDF locally
    }
  
  useEffect(() => {
    setReload(true);
      fetch(`${protocol}//api.fluterpay.ng/server/reciept.php`, {
        method: "POST",
        body: JSON.stringify({
          user_id: user.unique_id,
          ref: ref,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(response => response.text())
      .then(data => {
        if(data.includes("<table>")){
          setRawHtml(data);
        }else{
          showMessage("error", data);
          setTimeout(()=> {
            window.history.back();
          }, 5000);
        }
        setReload(false);
      })
      .catch(err => {
        showMessage("error", "Unable to get reciept please reload ");
        setReload(false);
      });
  }, [user, ref]);
  
  return (
    <>
      <div 
      dangerouslySetInnerHTML={{ __html: rawHtml }} 
      />
      
      {reload ? (
        <div class="dom-loading">
          <div>
            <div class="roller"></div>
            <img src={logo} />
          </div>
        </div>
      ) : ""}
    </>
    )
}

export default Reciept;