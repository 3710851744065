import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'remixicon/fonts/remixicon.css';
import PrivateRoute from './privateRoute';
import Logout from './components/logout';
import Login from './components/login';
import Signup from './components/signup';
import ForgotPassword from './components/forgot-password';
import Dashboard from './components/dashboard';
import Airtime from './components/airtime';
import Data from './components/data';
import Cable from './components/cable';
import Electricity from './components/electricity';
import Profile from './components/profile';
import Reciept from './components/reciept';
import Settings from './components/settings';
import Fund from './components/fund';
import AccountVerification from './components/account-verification';
import Transactions from './components/transactions';
import UserTransfer from './components/user-to-user';
import Lisense from './components/lisense'
import './css/inputs.css';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/lisense" element={<Lisense />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/airtime" element={<PrivateRoute><Airtime /></PrivateRoute>} />
          <Route path="/data" element={<PrivateRoute><Data /></PrivateRoute>} />
          <Route path="/cable" element={<PrivateRoute><Cable /></PrivateRoute>} />
          <Route path="/electricity" element={<PrivateRoute><Electricity /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/settings/*" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route path="/account-verification/*" element={<PrivateRoute><AccountVerification /></PrivateRoute>} />
          <Route path="/reciept" element={<PrivateRoute><Reciept /></PrivateRoute>} />
          <Route path="/transactions" element={<PrivateRoute><Transactions /></PrivateRoute>} />
          <Route path="/fund-wallet" element={<PrivateRoute><Fund /></PrivateRoute>} />
          <Route path="/to-fluterpay" element={<PrivateRoute><UserTransfer /></PrivateRoute>} />
        </Routes>
    </Router>
  );
}

export default App;
