import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, Navigate } from 'react-router-dom'
import ChangePassword from './includes/change-password'
import ChangePin from './includes/change-pin'
import showMessage from './showMessage'
import '../css/settings.css'

function Settings(){
  const g_user = sessionStorage.getItem("user");
  const user = JSON.parse(g_user);
  
  function goBack(){
    window.history.back();
  }
  
  
  return(
    <>
      <div class="top-fixed p-head">
        <i className="bi bi-chevron-left no" onClick={goBack}></i>
        <span>Settings</span>
      </div>
      
      <Routes>
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="change-pin" element={<ChangePin />} />
      </Routes>
      
      <div className="form-topic no-bottom top-space extra-more m">Account</div>
       <div className="btns">
         <i className="bi bi-bar-chart no"></i>
         <span>Upgrade to Premium</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
       <div className="btns">
         <i className="bi bi-people no"></i>
         <span>Referrals</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
       <Link to="/account-verification"><div className="btns">
         <i className="bi bi-check2-circle no"></i>
         <span>Account Verification</span>
         <i className="bi bi-chevron-right no"></i>
       </div></Link>
       <div className="btns">
         <i className="bi bi-bank no"></i>
         <span>Account Statement</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
       <div className="btns">
         <i className="bi bi-book no"></i>
         <span>Open Tour/Guide</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
       
       
       <div className="form-topic no-bottom top-space m">Security</div>
       <Link to="/settings/change-password"><div className="btns">
         <i className="bi bi-key no"></i>
         <span>Change Password</span>
         <i className="bi bi-chevron-right no"></i>
       </div></Link>
       <Link to="/settings/change-pin"><div className="btns">
         <i className="bi bi-lock no"></i>
         <span>Change Transaction PIN</span>
         <i className="bi bi-chevron-right no"></i>
       </div></Link>
       <div className="btns">
         <i className="bi bi-lock no"></i>
         <span>Reset Transaction PIN</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
       <div className="btns">
         <i className="bi bi-shield-lock no"></i>
         <span>Two Factor Authentication</span>
         <i className="bi bi-chevron-right no"></i>
       </div>
    </>
  );
}

export default Settings;