import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, useLocation, Outlet } from 'react-router-dom'
import Activity from './includes/activity';
import Home from './home';
import Rewards from './rewards';
import Services from './services';
import Support from './support';
import Account from './account';
import '../css/dashboard.css';
import avatarImg from '../logos/logo2.png';

function Dashboard() {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  
  useEffect(()=> {
    setActivePath(location.pathname);
  }, [location.pathname]);
  
  const user_get = sessionStorage.getItem("user");
  const user = JSON.parse(user_get);
  
  return (
    <>
      <Activity />
      <div className="top-head">
        <div className="image">
          <img src={avatarImg} alt="Avatar" />
          <div>
            <b>Hi, {user.acc_type === "wallet" ? user.fname : user.bs_name}</b>
            <small>{user.level}</small>
          </div>
        </div>
        <Link to="/dashboard/rewards"><i className="bi bi-gift"></i></Link>
        <Link to="/dashboard/support"><i className="bi bi-headset"></i></Link>
        <Link to="/notification"><i className="bi bi-bell"></i></Link>
      </div>
      
      <div className="content">
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="services" element={<Services />} />
          <Route path="support" element={<Support />} />
          <Route path="account" element={<Account />} />
        </Routes>
        <Outlet />
      </div>
      
      <div className="bottom">
        <Link to="/dashboard">
          <div className={`bottom-nav ${activePath == "/dashboard" ? "active" : ""}`}>
            <i className={activePath == "/dashboard" ? "bi bi-house-fill" : "bi bi-house"}></i>
            <span>Home</span>
          </div>
        </Link>
        <Link to="/dashboard/rewards">
          <div className={`bottom-nav ${activePath == "/dashboard/rewards" ? "active" : ""}`}>
            <i className={activePath == "/dashboard/rewards" ? "bi bi-gift-fill" : "bi bi-gift"}></i>
            <span>Rewards</span>
          </div>
        </Link>
        <Link to="/dashboard/services">
          <div className={`bottom-nav ${activePath == "/dashboard/services" ? "active" : ""}`}>
            <i className={activePath == "/dashboard/services" ? "bi bi-grid-fill" : "bi bi-grid"}></i>
            <span>Services</span>
          </div>
        </Link>
        <Link to="/dashboard/support">
          <div className={`bottom-nav ${activePath == "/dashboard/support" ? "active" : ""}`}>
            <i className={activePath == "/dashboard/support" ? "bi bi-chat-left-dots-fill" : "bi bi-chat-left-dots"}></i>
            <span>Support</span>
          </div>
        </Link>
        <Link to="/dashboard/account">
          <div className={`bottom-nav ${activePath == "/dashboard/account" ? "active" : ""}`}>
            <i className={activePath == "/dashboard/account" ? "bi bi-person-fill" : "bi bi-person"}></i>
            <span>Account</span>
          </div>
        </Link>
      </div>
      </>
  );
}

export default Dashboard;